import { Podcast, UpdatePodcastForm } from '@models/Podcast';
import { request } from './http';
import getFormData from '../utils/getFormData';

export async function getAllPodcast(): Promise<Podcast[]> {
  const res = await request('GET', '/podcast/list', undefined, {});
  return res.data.podcasts;
}

export async function getPodcast(podcastId: string): Promise<Podcast> {
  const res = await request('GET',
    '/podcast/get_podcast',
    undefined,
    { id: podcastId, });
  return res.data.podcast;
}

export async function updatePodcast(form: UpdatePodcastForm): Promise<Podcast> {
  const req = getFormData(form);
  const res = await request(
    'POST',
    '/admin/podcast/update',
    req,
    {}
  );
  return res.data.podcast;
}
