import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SectionListPage from '../pages/SectionListPage';
import SectionDetailPage from '../pages/SectionDetailPage';

const SectionRouter = () => {
  return (
    <Switch>
      <Route exact path="/section" component={SectionListPage}/>
      <Route exact path="/section/:id" component={SectionDetailPage}/>
    </Switch>
  );
};

export default SectionRouter;
