import React, { useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Toggle,
} from 'rsuite';
import logoIcon from '../assets/logo.svg';
import useAPIs from '../hooks/useAPIs';
import useEnv from '../hooks/useEnv';
import useToken from '../hooks/useToken';

const LoginPage = () => {
  const apis = useAPIs();
  const token = useToken();
  const { env, changeEnv } = useEnv();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  const login = () => {
    setLoading(true);

    let loginEmail = email;
    if (!email.includes('@hoost.tw')) loginEmail += '@hoost.tw';

    apis.user
      .login(loginEmail, password)
      .then((res) => {
        token.update(env, res.auth_token);
        Alert.success('身分驗證成功');
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        Alert.error('身分驗證失敗，請確認您的登入資訊');
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img
          alt="Logo"
          src={logoIcon}
          style={{
            width: 64,
            marginBottom: 32,
          }}
        />
        <p>我們需要先驗證您的身分。</p>
        <Form
          style={{
            textAlign: 'left',
            margin: '32px 0',
          }}
        >
          <FormGroup>
            <ControlLabel>電子信箱 Email</ControlLabel>
            <InputGroup>
              <FormControl
                name="email"
                type="email"
                value={email}
                onChange={(v) => setEmail(v)}
                onPressEnter={() => login()}
              />
              <InputGroup.Addon>@hoost.tw</InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <ControlLabel>密碼 Password</ControlLabel>
            <FormControl
              name="password"
              type="password"
              value={password}
              onChange={(v) => setPassword(v)}
              onPressEnter={() => login()}
            />
          </FormGroup>
        </Form>
        <Button onClick={() => login()} loading={isLoading}>
          登入
        </Button>
        <div style={{ marginTop: 64 }}>
          <Toggle
            checked={env === 'production'}
            onChange={(v) => changeEnv(v ? 'production' : 'development')}
          />
          {env === 'production' ? (
            <p
              style={{
                color: 'yellow',
                marginTop: 18,
              }}
            >
              正式站
              <br />
              https://www.hoost.tw
            </p>
          ) : (
            <p
              style={{
                color: 'gray',
                marginTop: 18,
              }}
            >
              測試站
              <br />
              https://dev.hoost.tw/
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
