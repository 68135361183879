import React from 'react';
import { Container, Message, Row } from 'rsuite';

const Episodes = () => {
  return (
    <Container className="container">
      <Row>
        <h2>音頻管理</h2>
        <h4>Episodes</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <Message
          type="warning"
          description="本頁面仍在開發中，請選擇其他頁面查看。"
        />
      </Row>
    </Container>
  );
};

export default Episodes;
