import { useAppDispatch, useAppSelector } from '@hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Loader,
  Modal,
} from 'rsuite';
import { refreshCoupon } from '@store/coupon/action';
import { emptyUpdatePodcastForm, Podcast } from '@models/Podcast';
import { updatePodcast } from '@services/podcast';
import { refreshOnePodcast } from '@store/podcast/action';

function EditPodcastModal(props: any) {
  const [formData, setFormData] = useState(emptyUpdatePodcastForm);
  const [isUpdating, setUpdating] = useState(false);
  const dispatch = useAppDispatch();
  const podcast = useAppSelector<Podcast | undefined>((r) =>
    r.podcast.podcasts.find(p => p.id === props.podcastId)
  );

  useEffect(() => {
    if (!podcast && props.podcastId) {
      dispatch(refreshOnePodcast(props.podcastId));
      return;
    }
    if (podcast) {
      setFormData({
        instagram_handle: podcast.instagram_handle,
        url1: podcast.url1,
        youtube_url: podcast.youtube_url,
        facebook_handle: podcast.facebook_handle
      });
    }
  }, [props.couponId, podcast]);

  async function submit() {
    setUpdating(true);
    try {
      await updatePodcast(formData);
      props.onClose();
      await dispatch(refreshCoupon());
    } catch (err) {
      Alert.error('搜尋失敗：' + err.toString());
    } finally {
      setUpdating(false);
    }
  }

  return (
    <Modal backdrop={true} show={props.isOpened} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>編輯頻道</Modal.Title>
      </Modal.Header>
      {!podcast ? (
        <Modal.Body>
          <Loader/>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Form formValue={formData} onChange={(d: any) => setFormData(d)}>
            <FormGroup>
              <ControlLabel>Facebook 連結</ControlLabel>
              <FormControl name="facebook_handle"/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Instagram 連結</ControlLabel>
              <FormControl name="instagram_handle"/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>網站連結</ControlLabel>
              <FormControl name="url1"/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>YouTube 連結</ControlLabel>
              <FormControl name="youtube_url"/>
            </FormGroup>
          </Form>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button onClick={submit} appearance="primary" loading={isUpdating}>
          確定
        </Button>
        <Button onClick={() => props.onClose()} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditPodcastModal;
