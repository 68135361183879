import CreateEventModal from '@components/CreateEventModal';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Container, Icon, IconButton, Row, Table } from 'rsuite';
import HoostEvent from '../models/HoostEvent';
import { getAllEvent } from '@services/event';

const {
  Column,
  HeaderCell,
  Cell
} = Table;

const EventListPage = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [eventData, setEventData] = useState<HoostEvent[]>();
  const [isCreatingEvent, setCreatingEvent] = useState(false);

  async function refresh() {
    setLoading(true);
    try {
      const res = await getAllEvent();
      setEventData(res);
    } catch (err) {
      Alert.error('從後端請求 Event 資料失敗：' + err.toString());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (history.location.pathname === '/event') refresh();
  }, [history.location.pathname]);

  return (
    <Container className="container">
      <Row>
        <h2>活動管理</h2>
        <h4>Events</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <IconButton
          appearance="primary"
          icon={<Icon icon="plus"/>}
          onClick={() => setCreatingEvent(true)}
          placement="left"
        >
          新增活動
        </IconButton>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <Table
          autoHeight
          data={eventData}
          onRowClick={(topic) => history.push('/event/' + topic.id)}
          loading={isLoading}
        >
          <Column width={240}>
            <HeaderCell>標題</HeaderCell>
            <Cell dataKey="title"/>
          </Column>

          <Column width={360}>
            <HeaderCell>描述</HeaderCell>
            <Cell dataKey="description"/>
          </Column>
        </Table>
      </Row>

      <CreateEventModal
        isOpened={isCreatingEvent}
        setOpened={setCreatingEvent}
        onCreated={async () => {
          setCreatingEvent(false);
          await refresh();
        }}
      />
    </Container>
  );
};

export default EventListPage;
