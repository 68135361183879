import { Alert } from 'rsuite';
import { API_VERSION } from '../config';
import {
  Episode,
  GetAllPodcastsResponse,
  GetAllTopicsResponse,
  GetTopicsResponse,
  LoginResponse,
  Podcast,
  Topic
} from '../models';
import PodcastType from '../models/PodcastType';
import validateEmail from '../utils/validateEmail';
import { useAppSelector } from './useAppSelector';
import useEnv from './useEnv';

const useAPIs = () => {
  const { env } = useEnv();
  const t = useAppSelector<string | undefined>((r) => r.session[env]?.token);

  const authedFetch = (
    url: string,
    method: 'GET' | 'POST',
    body?: string | FormData
  ) => {
    const origin =
      env === 'production'
        ? 'https://www.hoost.tw/api/'
        : 'https://dev.hoost.tw/api/';

    const headers = new Headers({ 'auth-token': t || 'empty' });

    if (typeof body === 'string')
      headers.append('Content-Type', 'application/json');

    return fetch(origin + API_VERSION + url, {
      method,
      body,
      headers,
    });
  };

  return {
    user: {
      login: (email: string, password: string) => {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('provider', 'email');

        return new Promise<LoginResponse>((resolve, reject) => {
          authedFetch('/login', 'POST', formData)
            .then((res) => res.json())
            .then((res: LoginResponse) => {
              if (res.result) {
                resolve(res);
              } else {
                reject(new Error());
              }
            })
            .catch(() => reject(new Error()));
        });
      },
    },

    episode: {
      search: async (keyword: string) => {
        const res = await authedFetch(
          `/admin/search?q=${keyword}&type=episode`,
          'GET'
        );
        const parsedRes = await res.json();
        if (res.status === 204) return [];
        if (res.status !== 200)
          throw new Error('後端回傳了錯誤代碼 ' + res.status);
        return parsedRes.data as Episode[];
      },
    },

    topic: {
      getAll: (offset: number, type?: 'free' | 'paid') => {
        let typeParam = '';

        if (type === 'free') typeParam = '&type=1';
        else if (type === 'paid') typeParam = '&type=2';

        return new Promise<GetAllTopicsResponse>((resovle, reject) => {
          authedFetch('/admin/topic/all?offset=' + offset + typeParam, 'GET')
            .then((res) => res.json())
            .then((res: GetAllTopicsResponse) => {
              if (res.result) resovle(res);
              else reject(new Error());
            })
            .catch(() => reject(new Error()));
        });
      },

      get: (id: string) => {
        return new Promise<Topic>((resolve, reject) => {
          authedFetch('/topic/get_topic?id=' + id, 'GET')
            .then((res) => res.json())
            .then((res) => {
              if (res.result) resolve(res.topic);
              else reject(res);
            })
            .catch((err) => reject(err));
        });
      },

      setStatus: (topicID: string, status: number) => {
        return new Promise((resolve, reject) => {
          authedFetch(
            '/admin/topic/set_status?id=' + topicID + '&status=' + status,

            'POST'
          )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      },

      search: (keyword: string, podcastID?: string) => {
        let url = '';
        if (validateEmail(keyword)) {
          Alert.info('使用電子信箱 ' + keyword + ' 搜尋中');
          url =
            '/admin/search?email=' +
            keyword +
            '&type=topic&show_episodes=1' +
            (podcastID ? '&podcast_id=' + podcastID : '');
        } else {
          url =
            '/admin/search?q=' +
            keyword +
            '&type=topic&show_episodes=1' +
            (podcastID ? '&podcast_id=' + podcastID : '');
        }
        return new Promise<Topic[]>((resolve, reject) => {
          authedFetch(url, 'GET')
            .then((res) =>
              res.json().then((res) => {
                if (res.data !== undefined) resolve(res.data);
                else reject(res.message);
              })
            )
            .catch((err) => reject(err));
        });
      },
    },

    podcast: {
      search: (keyword: string) => {
        let url = '';
        if (validateEmail(keyword)) {
          Alert.info('使用電子信箱 ' + keyword + ' 搜尋中');
          url =
            '/admin/search?email=' + keyword + '&type=podcast&show_episodes=1';
        } else {
          url = '/admin/search?q=' + keyword + '&type=podcast&show_episodes=1';
        }

        return new Promise<Podcast[]>((resolve, reject) => {
          authedFetch(url, 'GET')
            .then((res) =>
              res.json().then((res) => {
                const rd = res.data as Podcast[];
                if (rd !== undefined) resolve(rd);
                else reject(res.message);
              })
            )
            .catch((err) => reject(err));
        });
      },

      getAll: (offset: number) => {
        return new Promise<GetAllPodcastsResponse>((resovle, reject) => {
          authedFetch('/admin/podcast/all?offset=' + offset, 'GET')
            .then((res) => res.json())
            .then((res: GetAllPodcastsResponse) => {
              if (res.result) resovle(res);
              else reject(new Error());
            })
            .catch((err) => reject(err));
        });
      },

      getList: (recommandTypeId: number, unlimited: boolean) => {
        return new Promise<Podcast[]>((resovle, reject) => {
          authedFetch(
            '/podcast/get_podcast_list?recommend_type_id=' +
            recommandTypeId +
            '&unlimited=' +
            unlimited,
            'GET'
          )
            .then((res) => res.json())
            .then((res) => {
              if (res.result) resovle(res.datas);
              else reject(new Error());
            })
            .catch((err) => reject(err));
        });
      },

      get: (id: string) => {
        return new Promise<Podcast>((resolve, reject) => {
          authedFetch('/podcast/get_podcast?id=' + id, 'GET')
            .then((res) => res.json())
            .then((res) => {
              if (res.result) resolve(res.podcast);
              else reject(res);
            })
            .catch((err) => reject(err));
        });
      },

      getTopics: (podcastID?: string) => {
        return new Promise<Topic[]>((resolve, reject) => {
          authedFetch(
            '/topic/all?&show_episodes=1' +
            (podcastID ? '&podcast_id=' + podcastID : ''),
            'GET'
          )
            .then((res) =>
              res.json().then((res: GetTopicsResponse) => {
                if (res.datas !== undefined) resolve(res.datas);
                else reject(new Error());
              })
            )
            .catch((err) => reject(err));
        });
      },

      getEpisodes: (podcastID: string) => {
        return new Promise<Episode[]>((resolve, reject) => {
          authedFetch('/episode/all?podcast_id=' + podcastID, 'GET')
            .then((res) => {
              if (res.ok) {
                res.json().then((res) => {
                  resolve(res.datas);
                });
              } else reject(new Error());
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            });
        });
      },

      setRole: (podcastID: string, isCreator: boolean) => {
        return new Promise<any>((resolve, reject) => {
          const requestBody = new FormData();
          requestBody.append('id', podcastID);
          requestBody.append('is_creator', isCreator.toString());

          let ok = false;
          authedFetch('/admin/podcast/set_topic_role', 'POST', requestBody)
            .then((res) => {
              ok = res.ok;
              return res.json();
            })
            .then((res) => {
              if (ok) resolve(res);
              else reject(new Error());
            })
            .catch((err) => reject(err));
        });
      },

      setRecommends: (podcasts: Podcast[], recommandTypeId: number) => {
        return new Promise<any>((resolve, reject) => {
          const requestBody = new FormData();
          let ids = '';
          podcasts.map((pod) => (ids += pod.id + ','));
          ids.slice(0, -1);
          requestBody.append('podcast_ids', ids);
          requestBody.append('recommend_type_id', recommandTypeId.toString());

          let ok = false;
          authedFetch(
            '/admin/podcast/set_recommend_podcast_index',
            'POST',
            requestBody
          )
            .then((res) => {
              ok = res.ok;
              return res.json();
            })
            .then((res) => {
              if (ok) resolve(res);
              else reject(new Error());
            })
            .catch((err) => reject(err));
        });
      },
    },

    recommendType: {
      getAll: () => {
        return new Promise<PodcastType[]>((resolve, reject) => {
          authedFetch('/recommend_type/list', 'GET')
            .then((res) => res.json())
            .then((res) => {
              const d: PodcastType[] = res.data;
              if (res.result) resolve(d);
              else reject(new Error());
            })
            .catch((err) => reject(err));
        });
      },
    },
  };
};

export default useAPIs;
