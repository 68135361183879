import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'rsuite';

const PageNotFound = () => {
  return (
    <Grid
      style={{ paddingTop: 98, paddingBottom: 98 }}
      className="topic_detail"
    >
      <h1>404 Not found</h1>
      <p>你打錯網址了</p>
      <Link to="/">
        <p style={{ marginTop: 48 }}>回總覽頁面</p>
      </Link>
    </Grid>
  );
};

export default PageNotFound;
