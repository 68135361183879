import { useAppDispatch } from '@hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  InputNumber,
  Modal,
} from 'rsuite';
import { emptyCreateSkuForm, Topic } from '@models/Topic';
import { refreshOneTopic } from '@store/topic/action';
import { createSku } from '@services/topic';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  topic: Topic;
}

function CreateSkuModal(props: Props) {
  const [formData, setFormData] = useState(emptyCreateSkuForm);
  const [isCreating, setCreating] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFormData({
      ...formData,
      topic_id: props.topic.id
    });
  }, []);

  async function submit() {
    setCreating(true);
    try {
      await createSku(formData);
      props.onClose();
      await dispatch(refreshOneTopic(props.topic.id));
    } catch (err) {
      Alert.error(err.toString());
    } finally {
      setCreating(false);
    }
  }

  return (
    <Modal backdrop={true} show={props.isOpened} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>編輯課程方案 (SKU)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form formValue={formData} onChange={(d: any) => setFormData(d)}>
          <FormGroup>
            <ControlLabel>課程 ID</ControlLabel>
            <FormControl
              value={props.topic.id}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>課程名稱</ControlLabel>
            <FormControl
              value={props.topic.name}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>方案名稱</ControlLabel>
            <FormControl name="sku_name"/>
            <HelpBlock>name</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>方案介紹</ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={10}
              name="sku_description"/>
            <HelpBlock>description</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>售價</ControlLabel>
            <FormControl
              prefix="NT$"
              accepter={InputNumber}
              step={1}
              min={0}
              name="price"/>
            <HelpBlock>price</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>目標人數</ControlLabel>
            <FormControl
              postfix="人"
              accepter={InputNumber}
              step={1}
              min={0}
              name="target_number"/>
            <HelpBlock>target_number</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>購買需填寫的欄位</ControlLabel>
            <FormControl name="required_field"/>
            <HelpBlock>required_field</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>補充名稱</ControlLabel>
            <FormControl name="additional_name"/>
            <HelpBlock>additional_name</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>補充介紹</ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={10}
              name="additional_detail"/>
            <HelpBlock>additional_detail</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>額外售價</ControlLabel>
            <FormControl
              prefix="NT$"
              accepter={InputNumber}
              step={1}
              min={0}
              name="additional_price"/>
            <HelpBlock>additional_price</HelpBlock>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={submit} appearance="primary" loading={isCreating}>
          確定
        </Button>
        <Button onClick={() => props.onClose()} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateSkuModal;
