import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from 'rsuite';
import { Topic } from '@models/Topic';
import { giveTopic } from '@services/topic';

interface FormData {
  email: string;
  topic_id: string;
}

const emptyForm: FormData = {
  email: '',
  topic_id: ''
};

interface Props {
  topic: Topic;
  isOpened: boolean;
  onClose: () => void;
}

function GiveTopicModal(props: Props) {
  const [formData, setFormData] = useState(emptyForm);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (props.topic) {
      setFormData({
        ...formData,
        topic_id: props.topic.id
      });
    }
  }, [props.topic]);

  async function submit() {
    setLoading(true);
    try {
      await giveTopic(formData);
      props.onClose();
      Alert.success('手動開通課程成功');
    } catch (err) {
      Alert.error('發生錯誤：' + err.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal backdrop={true} show={props.isOpened} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>手動開通課程</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form formValue={formData} onChange={(d: any) => setFormData(d)}>
          <FormGroup>
            <ControlLabel>課程</ControlLabel>
            <FormControl
              value={props.topic.name + ' (' + props.topic.id + ')'}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>會員 Email</ControlLabel>
            <FormControl name="email"/>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={submit} appearance="primary" loading={isLoading}>
          確定
        </Button>
        <Button onClick={() => props.onClose()} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GiveTopicModal;
