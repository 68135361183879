import logoIcon from '@assets/logo.svg';
import React, { useEffect, useState } from 'react';
import './index.sass';

const ScreenWidthWarning = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  if (screenWidth <= 1024) {
    return (
      <div className="screen-width-warning">
        <img alt="Logo" src={logoIcon} />
        <p>對不起，Hoost 官方後台不支持使用移動設備查看。</p>
        <p>若您目前使用桌面瀏覽器，請將視窗調大後重新整理頁面。</p>
      </div>
    );
  }
  return <></>;
};

export default ScreenWidthWarning;
