import { SectionAction, SectionState } from './types';

const initState: SectionState = { sections: [], deletingSectionIds: [] };

export function sectionReducer(
  state: SectionState = initState,
  action: SectionAction
): SectionState {
  switch (action.type) {
    case 'section/set_sections':
      return { ...state, sections: action.payload };
    case 'section/set_deleting':
      let newIds = state.deletingSectionIds;
      newIds = [...newIds.filter((i) => i !== action.payload.id)];
      if (action.payload.isDeleting) newIds.push(action.payload.id);
      return {
        ...state,
        deletingSectionIds: newIds,
      };
    default:
      return state;
  }
}
