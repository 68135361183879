import UpdateEventModal from '@components/UpdateEventModal';
import { useAppDispatch, useAppSelector } from '@hooks/useAppSelector';
import { getEvent } from '@services/event';
import { deleteEvent } from '@store/event/action';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Container,
  Icon,
  IconButton,
  Message,
  Placeholder,
  Row
} from 'rsuite';
import { Enviornment } from 'src/types';
import HoostEvent from '../models/HoostEvent';

const EventDetailPage = () => {
  const history = useHistory();
  const env = useAppSelector<Enviornment>(r => r.session.activeEnv);
  const dispatch = useAppDispatch();
  const isDeleting = useAppSelector<boolean>(r =>
    r.event.deletingEventIds.some(i => i === eventData?.id));

  const [eventData, setEventData] = useState<HoostEvent | undefined>(undefined);

  const [isEditing, setEditing] = useState(false);

  const [imgState, setImagState] = useState<'loading' | 'loaded' | 'error'>(
    'loading'
  );

  async function refresh() {
    try {
      const eventId = +history.location.pathname.split('/')[2];
      const ed = await getEvent(eventId);
      setEventData(ed);
    } catch (err) {
      Alert.error(err.toString());
    }
  }

  useEffect(() => {
    refresh();
  }, [history.location.pathname, env]);

  function handleDelete() {
    if (!eventData) return;
    dispatch(deleteEvent(eventData.id));
  }

  if (!eventData) return <Loading/>;

  return (
    <Container className="container">
      <Row>
        <h2>活動管理</h2>
        <h4>Events</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <IconButton
          appearance="primary"
          icon={<Icon icon="pencil"/>}
          onClick={() => setEditing(true)}
          placement="left"
        >
          編輯活動
        </IconButton>
        <IconButton
          style={{ marginLeft: 16 }}
          color="red"
          icon={<Icon icon="trash"/>}
          onClick={handleDelete}
          loading={isDeleting}
          placement="left"
          disabled
        >
          刪除活動
        </IconButton>
      </Row>
      <div>
        <Row style={{ marginTop: 48 }}>
          <img
            onLoad={() => setImagState('loaded')}
            onError={() => setImagState('error')}
            style={{
              width: 240,
              display: imgState === 'loaded' ? 'block' : 'none',
            }}
            src={eventData.image}
            alt=""
          />

          {imgState === 'loading' ? (
            <Placeholder.Graph width={240} active/>
          ) : (
            <div/>
          )}

          {imgState === 'error' ? (
            <Message type="error" description="圖片已遺失或損毀" full={false}/>
          ) : (
            <div/>
          )}
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>活動標題</p>
          <h4>{eventData.title}</h4>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>活動描述</p>
          <h4>{eventData.description}</h4>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>活動連結</p>
          <h4>{eventData.url}</h4>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>活動標籤</p>
          <h4>{eventData.tag}</h4>
        </Row>
      </div>

      <UpdateEventModal
        defaultData={eventData}
        isOpened={isEditing}
        setOpened={() => setEditing(false)}
        onUpdated={async () => {
          await refresh();
          setEditing(false);
        }}
      />
    </Container>
  );
};

function Loading() {
  return (
    <Container className="container">
      <Row>
        <h2>Events</h2>
        <h4>活動管理</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <IconButton loading appearance="primary" icon={<Icon icon="pencil"/>}>
          編輯活動
        </IconButton>
      </Row>
      <div>
        <Row style={{ marginTop: 48 }}>
          <Placeholder.Graph width={240} active/>
        </Row>
        <Row style={{ marginTop: 48 }}>
          <p>活動標題</p>
          <Placeholder.Paragraph width={240} active/>
        </Row>
        <Row style={{ marginTop: 48 }}>
          <p>活動描述</p>
          <Placeholder.Paragraph width={240} active/>
        </Row>
      </div>
    </Container>
  );
}

export default EventDetailPage;
