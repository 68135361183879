import Section, { CreateSectionForm, UpdateSectionForm } from '@models/Section';
import { request } from './http';

export async function deleteSection(sectionId: number): Promise<void> {
  const req = new FormData();
  req.append('section_id', sectionId.toString());
  await request('POST', '/admin/section/remove_section', req, {});
}

export async function getAllSection(): Promise<Section[]> {
  const res = await request('GET', '/section/list', undefined, {});
  return res.data.sections;
}

export async function getSection(sectionId: number): Promise<Section> {
  const res = await request('GET',
    '/admin/section/get_section',
    undefined,
    { id: sectionId, });
  return res.data.section;
}

export async function createSection(form: CreateSectionForm): Promise<Section> {
  const ids_name = form.data_type + '_ids';
  const res = await request(
    'POST',
    '/admin/section/add_section',
    JSON.stringify({
      title: form.title,
      description: form.description,
      index: form.index,
      [ids_name]: form.ids,
      data_type: form.data_type,
    }),
    {}
  );
  return res.data.section;
}

export async function updateSection(form: UpdateSectionForm): Promise<Section> {
  const ids_name = form.data_type + '_ids';
  const res = await request(
    'POST',
    '/admin/section/set_section',
    JSON.stringify({
      id: form.id,
      title: form.title,
      description: form.description,
      index: form.index,
      [ids_name]: form.ids,
      data_type: form.data_type,
    }),
    {}
  );
  return res.data.section;
}
