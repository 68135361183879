import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Table, } from 'rsuite';
import useEnv from '../hooks/useEnv';
import { useAppDispatch, useAppSelector } from '@hooks/useAppSelector';
import { refreshCase } from '@store/case/action';
import { Case } from '@models/Case';
import ApplyCaseModal from '@components/ApplyCaseModal';

const {
  Column,
  HeaderCell,
  Cell
} = Table;

const Cases = () => {
  const history = useHistory();
  const { env } = useEnv();
  const dispatch = useAppDispatch();
  const cases = useAppSelector<Case[]>(r => r.case.cases);
  const isLoading = useAppSelector<boolean>(r => r.case.isRefreshing);

  useEffect(() => {
    dispatch(refreshCase()).then();
  }, [history.location.pathname, env]);

  return (
    <Container className="container">
      <Row>
        <h2>接案管理</h2>
        <h4>Case Management</h4>
      </Row>

      <Row style={{ marginTop: 36 }}>
        <Table
          loading={isLoading}
          autoHeight
          data={cases}
          onRowClick={(c) => dispatch({
            type: 'modals/open_modal',
            modal: ApplyCaseModal,
            props: { case: c }
          })}
        >
          <Column width={480}>
            <HeaderCell>標題</HeaderCell>
            <Cell dataKey="title"/>
          </Column>

          <Column width={120}>
            <HeaderCell>狀態</HeaderCell>
            <Cell dataKey="status"/>
          </Column>

          <Column width={360}>
            <HeaderCell>課程名稱</HeaderCell>
            <Cell>
              {(r: Case) => r.topic.name}
            </Cell>
          </Column>

          <Column width={80}>
            <HeaderCell>報名人數</HeaderCell>
            <Cell dataKey="total_apply_count"/>
          </Column>

        </Table>
      </Row>
    </Container>
  );
};

export default Cases;
