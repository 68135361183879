import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch } from 'redux-thunk';
import couponReducer from './coupon/reducer';
import { CouponAction, CouponState } from './coupon/types';
import { eventReducer } from './event/reducer';
import { EventAction, EventState } from './event/types';
import modalsReducer from './modal/reducer';
import { ModalAction, ModalsState } from './modal/types';
import { sectionReducer } from './section/reducer';
import { SectionAction, SectionState } from './section/types';
import { sessionReducer } from './session/reducer';
import { SessionAction, SessionState } from './session/types';
import topicReducer from './topic/reducer';
import { TopicAction, TopicState } from './topic/types';
import { PodcastAction, PodcastState } from '@store/podcast/types';
import podcastReducer from '@store/podcast/reducer';
import { CaseAction, CaseState } from '@store/case/types';
import caseReducer from '@store/case/reducer';

export interface RootState {
  event: EventState;
  modals: ModalsState;
  coupon: CouponState;
  section: SectionState;
  topic: TopicState;
  podcast: PodcastState;
  session: SessionState;
  case: CaseState;
}

export type RootAction =
  | PodcastAction
  | EventAction
  | ModalAction
  | CouponAction
  | SessionAction
  | SectionAction
  | CaseAction
  | TopicAction;

const store = createStore(
  combineReducers<RootState>({
    event: eventReducer,
    modals: modalsReducer,
    podcast: podcastReducer,
    coupon: couponReducer,
    section: sectionReducer,
    topic: topicReducer,
    case: caseReducer,
    session: sessionReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;

export type AppDispatch = ThunkDispatch<RootState, any, RootAction>;
