import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EventListPage from '../pages/EventListPage';
import EventDetailPage from '../pages/EventDetailPage';

const EventRouter = () => {
  return (
    <Switch>
      <Route exact path="/event" component={EventListPage}/>
      <Route exact path="/event/:id" component={EventDetailPage}/>
    </Switch>
  );
};

export default EventRouter;