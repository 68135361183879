import { useAppDispatch, useAppSelector } from '@hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  InputNumber,
  Modal,
  SelectPicker,
  Toggle,
} from 'rsuite';
import Coupon, { emptyEditCouponForm } from '../../models/Coupon';
import { refreshCoupon } from '@store/coupon/action';
import { updateCoupon } from '@services/coupon';

function EditCouponModal(props: any) {
  const [formData, setFormData] = useState(emptyEditCouponForm);
  const [isUpdating, setUpdating] = useState(false);
  const dispatch = useAppDispatch();
  const coupon = useAppSelector<Coupon | undefined>((r) =>
    r.coupon.coupons.find((c) => c.id === props.couponId)
  );

  useEffect(() => {
    if (coupon) {
      setFormData({
        ...coupon,
        amount: coupon.percentage ? 100 - coupon.amount : coupon.amount
      });
    }
  }, [props.couponId]);

  async function submit() {
    setUpdating(true);
    if (formData.percentage) formData.amount = 100 - formData.amount;
    try {
      await updateCoupon(formData);
      props.onClose();
      await dispatch(refreshCoupon());
    } catch (err) {
      Alert.error('搜尋失敗：' + err.toString());
    } finally {
      setUpdating(false);
    }
  }

  return (
    <Modal backdrop={true} show={props.isOpened} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>編輯折扣碼</Modal.Title>
      </Modal.Header>
      {!coupon ? (
        <Modal.Body>
          <p>查無折扣碼 {props.couponId} </p>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Form formValue={formData} onChange={(d: any) => setFormData(d)}>
            <FormGroup>
              <ControlLabel>折扣碼</ControlLabel>
              <FormControl name="code"/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>人數限制</ControlLabel>
              <FormControl name="limit" accepter={InputNumber}/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>開始日期</ControlLabel>
              <FormControl
                format="YYYY-MM-DD HH:mm:ss"
                cleanable={false}
                accepter={DatePicker}
                name="start_at"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>到期日</ControlLabel>
              <FormControl
                format="YYYY-MM-DD HH:mm:ss"
                cleanable={false}
                accepter={DatePicker}
                name="expiration"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>以百分比折扣</ControlLabel>
              <FormControl
                accepter={Toggle}
                checked={formData.percentage}
                name="percentage"/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>折扣額度</ControlLabel>
              <FormControl
                name="amount"
                accepter={InputNumber}
                prefix={formData.percentage ? undefined : 'NT$'}
                postfix={formData.percentage ? '%' : undefined}
              />
              {formData.percentage && <HelpBlock>輸入 80 代表八折</HelpBlock>}
            </FormGroup>
            <FormGroup>
              <ControlLabel>標籤</ControlLabel>
              <FormControl name="label"/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>承擔對象</ControlLabel>
              <FormControl
                searchable={false}
                cleanable={false}
                accepter={SelectPicker}
                name="pay_user"
                data={[
                  {
                    label: 'Hoost',
                    value: 'Hoost'
                  },
                  {
                    label: '創作者',
                    value: 'Creator'
                  },
                  {
                    label: '共同承擔',
                    value: 'Together'
                  },
                ]}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button onClick={submit} appearance="primary" loading={isUpdating}>
          確定
        </Button>
        <Button onClick={() => props.onClose()} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditCouponModal;
