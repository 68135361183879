import { TopicAction, TopicState } from './types';

const initState: TopicState = { topics: [], isRefreshing: false };

function topicReducer(state = initState, action: TopicAction): TopicState {
  if (typeof action !== 'object' || !action.type.startsWith('topic/'))
    return state;

  switch (action.type) {
    case 'topic/set_topic_list':
      return {
        ...state,
        topics: action.data,
      };
    case 'topic/set_refreshing_topics':
      return { ...state, isRefreshing: action.isRefreshing };
    case 'topic/set_topic':
      return {
        ...state,
        topics: [
          ...state.topics.filter((t) => t.id !== action.data.id),
          action.data,
        ],
      };
    default:
      console.warn('unknown action received', action);
      return state;
  }
}

export default topicReducer;
