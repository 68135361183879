interface HoostEvent {
  id: number,
  url: string,
  title: string
  description: string
  tag: string
  image: any
  image_url: string
}

export const emptyHoostEvent = {
  id: 0,
  title: '',
  url: '',
  description: '',
  tag: '',
  image: undefined,
  image_url: ''
};

export default HoostEvent;
