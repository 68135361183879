import { Episode } from './Episode';
import PodcastType from './PodcastType';

export interface Podcast {
  id: string;
  rss: string | null;
  email: string | null;
  image: string | null;
  rss_image: string | null;
  title: string | null;
  country: string | null;
  website: string | null;
  language: string | null;
  rss_genre: string | null;
  itunes_id: string | null;
  publisher: string | null;
  thumbnail: string | null;
  is_claimed: string | null;
  description: string | null;
  total_episodes: string | null;
  explicit_content: string | null;
  latest_pub_date_ms: string | null;
  earliest_pub_date_ms: string | null;
  next_episode_pub_date: string | null;
  is_creator: boolean | null;
  status: number | null;
  hosting_by: string | null;
  episodes: Episode[];
  index: number,
  recommend_types: PodcastType[]
  facebook_handle: string | null;
  instagram_handle: string | null;
  url1: string | null;
  youtube_url: string | null;
}

export interface UpdatePodcastForm {
  facebook_handle: string | null;
  instagram_handle: string | null;
  url1: string | null;
  youtube_url: string | null;
}

export const emptyUpdatePodcastForm: UpdatePodcastForm = {
  facebook_handle: '',
  instagram_handle: '',
  url1: '',
  youtube_url: ''
};
