import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Divider,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  InputNumber,
  Loader,
  Modal,
  Notification,
} from 'rsuite';
import api from '@services/index';
import { useAppSelector } from '@hooks/useAppSelector';
import { Case, CaseUser } from '@models/Case';
import { searchUser } from '@services/user';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  case: Case;
}

interface ApplyCaseForm {
  case_id: number;
  user_id: string;
  direct_profit: number;
  share_profit_percent: number;
}

const form: ApplyCaseForm = {
  case_id: 0,
  direct_profit: 0,
  share_profit_percent: 0.2,
  user_id: ''
};

function ApplyCaseModal(props: Props) {
  const [formData, setFormData] = useState(form);
  const session = useAppSelector(r => r.session);
  const [isCreating, setCreating] = useState(false);
  const [user, setUser] = useState<CaseUser>();
  const [isSearching, setSearching] = useState(false);
  const [idNumber, setIdNumber] = useState('');

  async function handleSearchUser() {
    setSearching(true);
    const res = await searchUser({ id_number: idNumber });
    if (res.length > 0) {
      setUser(res[0]);
    } else {
      Notification.warning({
        title: '發生錯誤',
        description: '身分證字號 ' + idNumber + ' 查無用戶'
      });
    }
    setSearching(false);
  }

  useEffect(() => {
    if (idNumber.length === 10) handleSearchUser();
    else setUser(undefined);
  }, [idNumber]);

  async function submit() {
    setCreating(true);
    const token = session[session.activeEnv]?.token;
    if (!token) return;
    if (!user) return;
    const f = { ...formData };
    f.case_id = props.case.id;
    f.user_id = user.id;
    try {
      await api.case.apply(session.activeEnv, token, f);
      props.onClose();
    } catch (err) {
      Alert.error(err.toString());
    } finally {
      setCreating(false);
    }
  }

  return (
    <Modal backdrop={true} show={props.isOpened} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>開通接案</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form formValue={formData} onChange={(d: any) => setFormData(d)}>
          <FormGroup>
            <ControlLabel>案件ID</ControlLabel>
            <FormControl value={props.case.id} readOnly/>
          </FormGroup>
          <FormGroup>
            <ControlLabel>案件名稱</ControlLabel>
            <FormControl value={props.case.title} readOnly/>
          </FormGroup>

          <Divider/>

          <FormGroup>
            <ControlLabel>使用者身分證字號</ControlLabel>
            <FormControl value={idNumber} onChange={setIdNumber}/>
            {isSearching && <Loader style={{ marginLeft: 12 }}/>}
          </FormGroup>

          {user && <React.Fragment>
            <FormGroup>
              <ControlLabel>使用者ID</ControlLabel>
              <FormControl
                value={user.id}
                readOnly/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>使用者姓名</ControlLabel>
              <FormControl
                value={user.bank_detail.name}
                readOnly/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>使用者信箱</ControlLabel>
              <FormControl
                value={user.email}
                readOnly/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>銀行</ControlLabel>
              <FormControl
                value={user.bank_detail.bank_name}
                readOnly/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>分行</ControlLabel>
              <FormControl
                value={user.bank_detail.branch_name}
                readOnly/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>銀行帳號</ControlLabel>
              <FormControl
                value={user.bank_detail.account_number}
                readOnly/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Hoost 錢包餘額</ControlLabel>
              <FormControl
                value={user.balance?.cash || '無資料'}
                readOnly/>
            </FormGroup>

            <Divider/>

            <FormGroup>
              <ControlLabel>稿費</ControlLabel>
              <FormControl
                name="direct_profit"
                accepter={InputNumber}
                prefix="NT$"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>分潤比例</ControlLabel>
              <FormControl
                name="share_profit_percent"
                accepter={InputNumber}
                step={0.01}
                min={0}
                max={1}
              />
              <HelpBlock>0.2 代表 20% 分潤</HelpBlock>
            </FormGroup>
          </React.Fragment>}

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={submit}
          disabled={!user}
          appearance="primary"
          loading={isCreating}>
          確定
        </Button>
        <Button onClick={() => props.onClose()} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ApplyCaseModal;
