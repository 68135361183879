import { updateEvent } from '@services/event';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Input, Modal, Uploader } from 'rsuite';
import HoostEvent, { emptyHoostEvent } from '../../models/HoostEvent';

interface UpdateEventModalProps {
  defaultData: HoostEvent;
  isOpened: boolean;
  setOpened: (opened: boolean) => void;
  onUpdated: () => void;
}

function UpdateEventModal(props: UpdateEventModalProps) {
  const [formData, setFormData] = useState<HoostEvent>(emptyHoostEvent);
  const [isLoading, setLoading] = useState(false);

  const {
    defaultData,
    isOpened,
    setOpened,
    onUpdated
  } = props;

  useEffect(() => {
    setFormData({
      ...defaultData,
      image: undefined,
      image_url: defaultData.image,
    });
  }, [defaultData]);

  async function handleUpdate() {
    try {
      setLoading(true);
      await updateEvent(formData);
      onUpdated();
    } catch (err) {
      Alert.error(err.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal backdrop={true} show={isOpened} onHide={() => setOpened(false)}>
      <Modal.Header>
        <Modal.Title>編輯活動</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <p>標題</p>
          <Input
            value={formData.title}
            onChange={(title) =>
              setFormData({
                ...formData,
                title,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>描述</p>
          <Input
            value={formData.description}
            onChange={(description) =>
              setFormData({
                ...formData,
                description,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>連結</p>
          <Input
            value={formData.url}
            onChange={(url) =>
              setFormData({
                ...formData,
                url,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>標籤</p>
          <Input
            value={formData.tag}
            onChange={(tag) =>
              setFormData({
                ...formData,
                tag,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>圖片</p>
          <Uploader
            autoUpload={false}
            data={formData.image}
            onChange={(f) =>
              setFormData({
                ...formData,
                image: f[0].blobFile,
              })
            }
          />
          {formData.image ? (
            <div/>
          ) : (
            <img src={formData.image_url} style={{ width: '60%' }} alt=""/>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUpdate} appearance="primary" loading={isLoading}>
          確定
        </Button>
        <Button onClick={() => setOpened(false)} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateEventModal;
