import { useAppDispatch, useAppSelector } from '@hooks/useAppSelector';
import { emptyUpdateTopicForm, Topic } from '@models/Topic';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  SelectPicker,
  Uploader,
} from 'rsuite';
import { refreshOneTopic } from '@store/topic/action';
import { editTopic } from '@services/topic';

function EditTopicModal(props: any) {
  const [formData, setFormData] = useState(emptyUpdateTopicForm);
  const [isUpdating, setUpdating] = useState(false);

  const dispatch = useAppDispatch();
  const topicId = props.topicId;
  const topic = useAppSelector<Topic | undefined>((r) =>
    r.topic.topics.find((t) => t.id === topicId)
  );

  useEffect(() => {
    if (!topic) dispatch(refreshOneTopic(topicId));
  }, [topic]);

  async function submit() {
    try {
      setUpdating(true);
      await editTopic(formData);
      await dispatch(refreshOneTopic(topicId));
      props.onClose();
    } catch (err) {
      Alert.error(err);
      console.error(err);
    } finally {
      setUpdating(false);
    }
  }

  useEffect(() => {
    if (topic)
      setFormData({
        description: topic.description,
        id: topic.id,
        name: topic.name,
        tax_percent: 0.05,
      });
  }, [topic]);

  return (
    <Modal backdrop={true} show={props.isOpened} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>課程管理</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          formValue={formData}
          onChange={(d: any) => setFormData(d)}
          style={{ padding: 12 }}>
          <FormGroup>
            <ControlLabel>課程名稱</ControlLabel>
            <FormControl name="name"/>
          </FormGroup>
          <FormGroup>
            <ControlLabel>課程介紹</ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={10}
              name="description"/>
          </FormGroup>
          <FormGroup>
            <ControlLabel>圖片 Image</ControlLabel>
            <Uploader
              autoUpload={false}
              data={formData.image}
              fileList={[]}
              onChange={(f) =>
                setFormData({
                  ...formData,
                  image: f[0].blobFile,
                })
              }
            >
              <img
                style={{
                  width: '80%',
                  objectFit: 'contain'
                }}
                src={formData.image
                  ? URL.createObjectURL(formData.image)
                  : topic?.image}
                alt=""/>
            </Uploader>
          </FormGroup>
          <FormGroup>
            <ControlLabel>橫幅 Banner</ControlLabel>
            <Uploader
              autoUpload={false}
              data={formData.banner}
              fileList={[]}
              onChange={(f) =>
                setFormData({
                  ...formData,
                  image: f[0].blobFile,
                })
              }
            >
              <img
                style={{
                  width: '80%',
                  objectFit: 'contain'
                }}
                src={formData.image
                  ? URL.createObjectURL(formData.banner)
                  : topic?.banner}
                alt=""/>
            </Uploader>
          </FormGroup>
          <FormGroup>
            <ControlLabel>影片</ControlLabel>
            <Uploader
              autoUpload={false}
              data={formData.video}
              fileList={[]}
              onChange={(f) =>
                setFormData({
                  ...formData,
                  video: f[0].blobFile,
                })
              }
            >
              <video
                style={{ width: '80%' }}
                autoPlay
                src={formData.video
                  ? URL.createObjectURL(formData.video)
                  : topic?.video}
              />
            </Uploader>
          </FormGroup>
          <FormGroup>
            <ControlLabel>營業稅率</ControlLabel>
            <FormControl
              accepter={SelectPicker}
              searchable={false}
              cleanable={false}
              data={[
                {
                  label: '0%',
                  value: 0
                }, {
                  label: '5%',
                  value: 0.05
                }
              ]}
              name="tax_percent"/>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={submit} loading={isUpdating}>
          確定
        </Button>
        <Button onClick={props.onClose} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditTopicModal;
