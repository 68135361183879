import {
  CreateSkuForm,
  EditSkuForm,
  Topic,
  UpdateTopicForm
} from '@models/Topic';
import { request } from './http';
import { User } from '@models/User';

export async function getOneTopic(id: string): Promise<Topic> {
  const res = await request('GET', '/topic/get_topic', undefined, { id });
  return res.data.topic;
}

interface GiveTopicResponse {
  purchase_count: number;
  user: User;
}

export async function giveTopic(form: any): Promise<GiveTopicResponse> {
  const res = await request(
    'POST', '/admin/topic/topic_free_to_user', JSON.stringify(form), {});
  if (!res.data.result) throw new Error(res.data.message);
  return res.data;
}

export async function setTopicSaleTimes(form: EditSkuForm): Promise<any> {

  const req = new FormData();
  req.append('id', form.id);
  req.append('pre_order_start', form.times.pre_order_start.toString());
  req.append('pre_order_end', form.times.pre_order_end.toString());
  req.append('early_bird_start', form.times.early_bird_start.toString());
  req.append('early_bird_end', form.times.early_bird_end.toString());
  req.append('normal_sale_start', form.times.normal_sale_start.toString());
  req.append('normal_sale_end', form.times.normal_sale_end.toString());
  req.append('content_release_time',
    form.times.content_release_time.toString());

  const res = await request(
    'POST', '/admin/topic/set_topic_sale_times', req, {});
  if (!res.data.result) throw new Error(res.data.message);
  return res.data;
}

export async function setTopicDiscount(form: EditSkuForm): Promise<any> {
  const res = await request(
    'POST', '/admin/topic/set_topic_discount', JSON.stringify({
      id: form.id,
      discounts: {
        preorder: form.discounts.preorder,
        earlybird: form.discounts.earlybird,
        normal: form.discounts.normal
      }
    }), {});
  if (!res.data.result) throw new Error(res.data.message);
  return res.data;
}

export async function updateSku(form: EditSkuForm): Promise<any> {
  const req = new FormData();
  req.append('sku_id', form.id);
  req.append('sku_name', form.name);
  req.append('sku_description', form.description);
  req.append('price', form.price.toString());
  req.append('target_number', form.target_number.toString());
  req.append('require_field', form.require_field);
  req.append('additional_name', form.additional_name);
  req.append('additional_detail', form.additional_detail);
  req.append('additional_price', form.additional_price.toString());
  const res = await request(
    'POST',
    '/admin/sku/update_sku',
    req,
    {});
  if (!res.data.result) throw new Error(res.data.message);
  return res.data;
}

export async function editTopic(form: UpdateTopicForm): Promise<Topic> {
  const req = new FormData();
  req.append('id', form.id);
  if (form.image) req.append('image', form.image);
  if (form.name) req.append('name', form.name);
  if (form.description) req.append('description', form.description);
  if (form.video) req.append('video', form.video);
  if (form.banner) req.append('banner', form.banner);
  if (form.tax_percent) req.append('tax_percent', form.tax_percent.toString());

  const res = await request(
    'POST',
    '/admin/topic/update',
    req,
    {});

  if (!res.data.result) throw new Error(res.data.message);

  return res.data.data;
}

export async function createSku(form: CreateSkuForm): Promise<any> {
  const req = new FormData();
  req.append('topic_id', form.topic_id);
  req.append('sku_name', form.sku_name);
  req.append('sku_description', form.sku_description);
  req.append('price', form.price.toString());
  req.append('sku_type', form.sku_type.toString());
  req.append('target_number', form.target_number.toString());
  req.append('required_field', form.required_field);
  req.append('additional_name', form.additional_name);
  req.append('additional_detail', form.additional_detail);
  req.append('additional_price', form.additional_price.toString());

  const res = await request(
    'POST',
    '/admin/sku/create_sku',
    req,
    {});

  if (!res.data.result) throw new Error(res.data.message);

  return res.data.data;
}
