/* eslint-disable camelcase */
import EditTopicModal from '@components/EditTopicModal';
import { useAppDispatch, useAppSelector } from '@hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Container,
  Divider,
  Icon,
  IconButton,
  Modal,
  Row,
} from 'rsuite';
import useAPIs from '../hooks/useAPIs';
import useEnv from '../hooks/useEnv';
import { Topic } from '../models';
import '../styles/TopicDetail.sass';
import getTopicStatusDescription from '../utils/getTopicStatusDescription';
import GiveTopicModal from '@components/GiveTopicModal';
import EditSkuModal from '@components/EditSkuModal';
import { refreshOneTopic } from '@store/topic/action';
import CreateSkuModal from '@components/CreateSkuModal';

const TopicDetail = () => {
  const apis = useAPIs();
  const history = useHistory();
  const { env } = useEnv();
  const dispatch = useAppDispatch();

  const topicID = history.location.pathname.split('/')[2];
  const topic = useAppSelector<Topic | undefined>(
    r => r.topic.topics.find(t => t.id === topicID));
  const [newStatus, setNewStatus] = useState(0);

  useEffect(() => {
    downloadTopicData(topicID);
  }, [history.location.pathname, env]);

  const getTotalDuration = () => {
    let n = 0;
    if (topic) {
      topic.episodes.map((ep) => {
        n += ep.audio_length_sec;
        return null;
      });
    }
    return n;
  };

  async function downloadTopicData(topicID: string) {
    await dispatch(refreshOneTopic(topicID));
  }

  const updateTopicStatus = () => {
    if (topic) {
      apis.topic
        .setStatus(topic.id, newStatus)
        .then(() => {
          downloadTopicData(topic.id);
          setNewStatus(0);
        })
        .catch((err) => Alert.error(err.toString()));
    }
  };

  const acceptTopic = () => {
    let newStatus = topic?.status;
    if (topic?.status === 1) newStatus = 2;
    else if (topic?.status === 8) newStatus = 16;

    if (topic && newStatus) setNewStatus(newStatus);
  };

  const deniedTopic = () => {
    let newStatus = topic?.status;
    if (topic?.status === 1) newStatus = 4;
    else if (topic?.status === 8) newStatus = 2;
    if (topic && newStatus) setNewStatus(newStatus);
  };

  function openCreateSkuModal() {
    dispatch({
      type: 'modals/open_modal',
      modal: CreateSkuModal,
      props: { topic }
    });
  }

  return (
    <Container className="container">
      {history.location.search.split('=')[1] !== undefined ? (
        <Link to={history.location.search.split('=')[1]}>
          {'<'} 返回頻道詳情
        </Link>
      ) : (
        <Link to="/topics">{'<'} 返回主題列表</Link>
      )}

      <div className={topic ? 'loaded' : 'loading'} style={{ paddingTop: 36 }}>
        <Col xs={16}>
          <Row>
            <Col xs={12} style={{ paddingRight: 36 }}>
              <Row>
                <img
                  style={{ width: '60%' }}
                  src={topic?.image}
                  alt="無法載入主題封面"
                />
              </Row>
              <Row style={{ marginTop: 36 }}>
                <h2>{topic?.name}</h2>
                <h5 style={{ color: topic?.is_free ? 'green' : 'orange' }}>
                  {topic?.is_free ? '免費主題' : '付費主題'}
                </h5>
                <p
                  style={{
                    opacity: 0.4,
                    fontSize: 10,
                    marginTop: 16,
                  }}
                >
                  主題 ID <br/>
                  {topic?.id}
                </p>
              </Row>
              <Row style={{ marginTop: 36 }}>
                <IconButton
                  appearance="primary"
                  icon={<Icon icon="pencil"/>}
                  onClick={() =>
                    dispatch({
                      type: 'modals/open_modal',
                      modal: EditTopicModal,
                      props: { topicId: topic?.id },
                    })
                  }
                  placement="left"
                >
                  課程管理
                </IconButton>
                <IconButton
                  appearance="primary"
                  icon={<Icon icon="gift"/>}
                  style={{ marginLeft: 18 }}
                  onClick={() =>
                    dispatch({
                      type: 'modals/open_modal',
                      modal: GiveTopicModal,
                      props: { topic: topic },
                    })
                  }
                  placement="left"
                >
                  手動開通
                </IconButton>
              </Row>
            </Col>
            <Col xs={12}>
              <p className="field_name">課程介紹</p>
              <h5>{topic?.description}</h5>
            </Col>
          </Row>

          {topic?.status === 1 || topic?.status === 8 ? (
            <div style={{ marginBottom: 72 }}>
              <Divider>管理操作</Divider>
              <div className="status_buttons">
                <Button color="green" onClick={() => acceptTopic()}>
                  審核通過
                </Button>
                <Button color="red" onClick={() => deniedTopic()}>
                  審核不通過
                </Button>
              </div>
            </div>
          ) : (
            <div/>
          )}

          <div>
            <Divider>統計數據</Divider>
            <Row>
              <Col xs={24} style={{ marginBottom: 36 }}>
                <p className="field_name">課程狀態</p>
                <h4>{getTopicStatusDescription(topic?.status)}</h4>
              </Col>
              <Col xs={8} style={{ marginBottom: 36 }}>
                <p className="field_name">總收聽次數</p>
                <h5>{topic?.total_listen_count} 次</h5>
              </Col>
              <Col xs={8} style={{ marginBottom: 36 }}>
                <p className="field_name">總時長</p>
                <h5>
                  {topic
                    ? Math.round(getTotalDuration() / 60) + ' 分鐘'
                    : 'Loading'}
                </h5>
              </Col>
            </Row>
          </div>

          {topic?.is_free ? (
            <></>
          ) : (
            <div style={{ marginBottom: 72 }}>
              <Divider>付費相關數據</Divider>
              <Row>
                <Col xs={8}>
                  <p className="field_name">定價</p>
                  <h5>{topic?.skus[0]
                    ? 'NT$ ' + topic?.skus[0]?.price
                    : '無資料'}</h5>
                </Col>
                <Col xs={8}>
                  <p className="field_name">總時長 / 定價</p>
                  <h5>
                    {topic?.skus[0]
                      ? getTotalDuration() / 60 / topic?.skus[0]?.price +
                      ' 元 / 分鐘'
                      : '無資料'}
                  </h5>
                </Col>
              </Row>
            </div>
          )}
        </Col>

        <Col xs={8} style={{ padding: '0 36px' }}>
          <Row>
            <p className="field_name">方案列表</p>
            <IconButton
              icon={<Icon icon="plus"/>}
              appearance="primary"
              onClick={openCreateSkuModal}>建立新方案</IconButton>
            {topic?.skus.length === 0 && <h5>這個課程還沒有方案</h5>}
            {topic?.skus.map((ep) => <div
              key={ep.id}
              className="list_item"
              onClick={() => dispatch({
                type: 'modals/open_modal',
                modal: EditSkuModal,
                props: {
                  sku: ep,
                  topic
                }
              })}
            >
              <h5>{ep.name}</h5>
            </div>
            )}
          </Row>
          <Row>
            <p className="field_name">音頻列表</p>
            {topic?.episode_count === 0 ? (
              <h5>這個頻道還沒有上傳音頻</h5>
            ) : (
              topic?.episodes.map((ep) => (
                <div
                  key={ep.id}
                  className="list_item"
                  onClick={() => history.push('/episode/' + ep.id)}
                >
                  <h5>{ep.title}</h5>
                  <p>
                    長度{' '}
                    {Math.round(ep.audio_length_sec / 60) +
                    ' 分 ' +
                    (ep.audio_length_sec % 60) +
                    '秒'}
                  </p>
                  <p>檔案大小 MB</p>
                  <p>總收聽 {ep.listen_times} 次</p>
                </div>
              ))
            )}
          </Row>
        </Col>
      </div>

      <Modal
        backdrop="static"
        show={newStatus !== 0}
        onHide={() => setNewStatus(0)}
        size="xs"
      >
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              margin: 16,
              color: '#ffb300',
              fontSize: 24,
            }}
          />
          <p style={{ color: 'gray' }}>
            你將把課程
            <strong style={{ color: 'white' }}>
              {topic?.name} （{topic?.id}）
            </strong>
            的狀態更新為
            <strong style={{ color: 'white' }}>
              {newStatus} （{getTopicStatusDescription(newStatus)}）
            </strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => updateTopicStatus()} appearance="primary">
            確定
          </Button>
          <Button onClick={() => setNewStatus(0)} appearance="subtle">
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TopicDetail;
