import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Container, Loader, Row, Table } from 'rsuite';
import useAPIs from '../hooks/useAPIs';
import { Podcast } from '../models';
import PodcastType from '../models/PodcastType';

const { Column, HeaderCell, Cell } = Table;

const PodcastRecommend = () => {
  const apis = useAPIs();
  const history = useHistory();

  const [isLoadingTypes, setLoadingTypes] = useState(false);
  const [recommendTypes, setRecommendTypes] = useState<PodcastType[]>([]);
  const [selectedType, setSelectedType] = useState<PodcastType | undefined>();

  const [selectedTypePodcasts, setSelectedTypePodcasts] = useState<Podcast[]>(
    []
  );
  const [isLoadingPodcasts, setLoadingPodcasts] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const [isUploading, setUploading] = useState(false);

  useEffect(() => {
    setLoadingTypes(true);
    apis.recommendType
      .getAll()
      .then((res: PodcastType[]) => {
        setRecommendTypes(res);
        setSelectedType(res[0]);
      })
      .catch((err) => Alert.error(err))
      .finally(() => setLoadingTypes(false));
  }, []);

  useEffect(() => {
    if (selectedType) {
      setHasChanges(false);
      setLoadingPodcasts(true);
      apis.podcast
        .getList(selectedType.id, true)
        .then((res) => setSelectedTypePodcasts(res))
        .catch((err) => Alert.error(err))
        .finally(() => setLoadingPodcasts(false));
    }
  }, [selectedType]);

  const setRecommendPodcasts = () => {
    if (selectedType && hasChanges) {
      setUploading(true);
      apis.podcast
        .setRecommends(
          selectedTypePodcasts.filter((pod) => pod.index === 1),
          selectedType.id
        )
        .then(() => {
          Alert.success('更新成功');
          setHasChanges(false);
        })
        .catch((err) => Alert.error(err))
        .finally(() => setUploading(false));
    }
  };

  return (
    <Container className="container">
      <Row>
        <h2>頻道置頂管理</h2>
        <h4>Podcasts Recommend</h4>
      </Row>
      <Row style={{ marginTop: 32 }}>
        {recommendTypes.map((rt) => (
          <Col key={rt.id} xs={3}>
            <Button
              appearance={selectedType?.id === rt.id ? 'primary' : 'default'}
              style={{
                width: '80%',
                marginBottom: 6,
                marginTop: 6,
              }}
              onClick={() => setSelectedType(rt)}
            >
              {rt.name}
            </Button>
          </Col>
        ))}
      </Row>
      {selectedType && !isLoadingTypes ? (
        <>
          <Row style={{ marginTop: 36 }}>
            <h4>「{selectedType.name}」 已置頂的頻道</h4>
          </Row>
          <Row style={{ marginTop: 36 }}>
            <Table
              autoHeight
              data={selectedTypePodcasts.filter((pod) => pod.index === 1)}
              loading={isLoadingPodcasts}
              renderEmpty={() => (
                <p
                  style={{
                    marginTop: 64,
                    textAlign: 'center',
                  }}
                >
                  未置頂任何頻道
                </p>
              )}
            >
              <Column width={360}>
                <HeaderCell>頻道名稱</HeaderCell>
                <Cell>
                  {(rowData: Podcast) => (
                    <a
                      onClick={() => history.push('/podcasts/' + rowData.id)}
                      style={{
                        color: 'white',
                        cursor: 'pointer',
                      }}
                    >
                      {rowData.title}
                    </a>
                  )}
                </Cell>
              </Column>

              <Column width={120}>
                <HeaderCell>取消置頂</HeaderCell>
                <Cell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {(rowData: Podcast) => (
                    <Button
                      style={{ width: '100%' }}
                      onClick={(e) => {
                        e.preventDefault();
                        setHasChanges(true);
                        setSelectedTypePodcasts((pods) => [
                          ...pods.filter((pod) => pod.id !== rowData.id),
                          {
                            ...rowData,
                            index: 0,
                          },
                        ]);
                      }}
                    >
                      取消置頂
                    </Button>
                  )}
                </Cell>
              </Column>
            </Table>
          </Row>
          {hasChanges ? (
            <Row style={{ marginTop: 36 }}>
              <Button loading={isUploading} onClick={setRecommendPodcasts}>
                保存變更
              </Button>
            </Row>
          ) : (
            <></>
          )}

          <Row style={{ marginTop: 36 }}>
            <h4>「{selectedType.name}」 未被置頂的頻道</h4>
          </Row>

          <Row style={{ marginTop: 36 }}>
            <Table
              autoHeight
              data={selectedTypePodcasts.filter((pod) => pod.index !== 1)}
              loading={isLoadingPodcasts}
            >
              <Column width={360}>
                <HeaderCell>頻道名稱</HeaderCell>
                <Cell>
                  {(rowData: Podcast) => (
                    <a
                      onClick={() => history.push('/podcasts/' + rowData.id)}
                      style={{
                        color: 'white',
                        cursor: 'pointer',
                      }}
                    >
                      {rowData.title}
                    </a>
                  )}
                </Cell>
              </Column>

              <Column width={120}>
                <HeaderCell>置頂</HeaderCell>
                <Cell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {(rowData: Podcast) => (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setHasChanges(true);
                        setSelectedTypePodcasts((pods) => [
                          ...pods.filter((pod) => pod.id !== rowData.id),
                          {
                            ...rowData,
                            index: 1,
                          },
                        ]);
                      }}
                    >
                      置頂
                    </Button>
                  )}
                </Cell>
              </Column>
            </Table>
          </Row>
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default PodcastRecommend;
