import { request } from './http';
import Coupon, { CreateCouponForm, EditCouponForm } from '@models/Coupon';

export async function deleteCoupon(couponId: number): Promise<void> {
  const req = new FormData();
  req.append('coupon_id', couponId.toString());
  await request('POST', '/admin/coupon/remove_coupon', req, {});
}

export async function getAllCoupon(): Promise<Coupon[]> {
  const res = await request('GET', '/admin/coupon/list', undefined, {});
  return res.data.coupons;
}

export async function createCoupon(form: CreateCouponForm): Promise<Coupon> {
  const res = await request(
    'POST',
    '/admin/coupon/generate',
    JSON.stringify(form),
    {}
  );
  return res.data.coupon;
}

export async function updateCoupon(form: EditCouponForm): Promise<Coupon> {
  const res = await request(
    'POST',
    '/admin/coupon/update',
    JSON.stringify(form),
    {}
  );
  return res.data.coupon;
}
