import React, { Component } from 'react';
import { Container, Message, Row, Table } from 'rsuite';
import TablePagination from 'rsuite/lib/Table/TablePagination';
import { Order } from '../models';

const { Column, HeaderCell, Cell } = Table;

interface States {
  downloading: boolean;
  page: number;
  displayLength: number;
  orders: Order[];
}

export default class Orders extends Component<{}, States> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      downloading: false,
      page: 0,
      displayLength: 0,
      orders: [],
    };
  }

  dataInPage = () => {
    const { displayLength, page, orders } = this.state;
    return orders.filter((_v, i) => {
      const start = displayLength * (page - 1);
      const end = start + displayLength;
      return i >= start && i < end;
    });
  };

  render() {
    return (
      <Container className="container">
        <Row>
          <h2>訂單管理</h2>
          <h4>Orders Mangement</h4>
        </Row>
        <Row style={{ marginTop: 36 }}>
          <Message
            type="warning"
            description="本頁面仍在開發中，請選擇其他頁面查看。"
          />
        </Row>
        <Row style={{ marginTop: 36 }}>
          <Table height={420} data={[]} loading={this.state.downloading}>
            <Column width={70}>
              <HeaderCell>Tier</HeaderCell>
              <Cell dataKey="status" />
            </Column>

            <Column width={180}>
              <HeaderCell>Topic Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>

            <Column width={180}>
              <HeaderCell>Channel</HeaderCell>
              <Cell dataKey="" />
            </Column>

            <Column width={120}>
              <HeaderCell>Subscribers</HeaderCell>
              <Cell dataKey="" />
            </Column>

            <Column width={120}>
              <HeaderCell>NoL</HeaderCell>
              <Cell dataKey="total_listen_count" />
            </Column>

            <Column width={120}>
              <HeaderCell>LT(hour)</HeaderCell>
              <Cell dataKey="" />
            </Column>

            <Column width={120}>
              <HeaderCell>Interactions</HeaderCell>
              <Cell dataKey="" />
            </Column>
          </Table>
          <TablePagination
            lengthMenu={[
              {
                value: 15,
                label: 15,
              },
              {
                value: 30,
                label: 30,
              },
              {
                value: 60,
                label: 60,
              },
              {
                value: 100,
                label: 100,
              },
            ]}
            activePage={this.state.page}
            displayLength={this.state.displayLength}
            total={this.state.orders.length}
            onChangePage={(v) => this.setState({ page: v })}
            onChangeLength={(v) => this.setState({ displayLength: v })}
          />
        </Row>
      </Container>
    );
  }
}
