import Modals from '@components/Modals';
import { useAppSelector } from '@hooks/useAppSelector';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { Col, Grid, Row } from 'rsuite';
import ScreenWidthWarning from './components/ScreenWidthWarning';
import Sidebar from './components/SideBar';
import Topbar from './components/TopBar';
import useEnv from './hooks/useEnv';
import LoginPage from './pages/LoginPage';
import MainRouter from './routes/MainRouter';
import store from './store';
import { SessionState } from './store/session/types';
import './styles/App.sass';
import './styles/CustomRsuite.less';

const App = () => {
  return (
    <Provider store={store}>
      <InsideProvider />
    </Provider>
  );
};

function InsideProvider() {
  const session = useAppSelector<SessionState>((r) => r.session);
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const { env } = useEnv();

  useEffect(() => {
    if (cookies.wwwToken)
      dispatch({
        type: 'session/login',
        env: 'production',
        token: cookies.wwwToken,
      });
    if (cookies.devToken)
      dispatch({
        type: 'session/login',
        env: 'development',
        token: cookies.devToken,
      });
  }, []);

  const token = session[env]?.token;

  if (!token) return <LoginPage />;

  return (
    <BrowserRouter>
      <ScreenWidthWarning />
      <Topbar />
      <Modals />
      <Grid fluid>
        <Row>
          <Col xs={4} style={{ padding: 0 }}>
            <Route component={Sidebar} />
          </Col>
          <Col xsPush={1} xs={18}>
            <MainRouter />
          </Col>
        </Row>
      </Grid>
    </BrowserRouter>
  );
}

export default App;
