import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Container,
  Icon,
  Input,
  InputGroup,
  Row,
  Table,
} from 'rsuite';
import TablePagination from 'rsuite/lib/Table/TablePagination';
import useAPIs from '../hooks/useAPIs';
import useEnv from '../hooks/useEnv';
import { Topic } from '../models';
import getTopicStatusDescription from '../utils/getTopicStatusDescription';

const { Column, HeaderCell, Cell } = Table;

const Topics = () => {
  const history = useHistory();
  const apis = useAPIs();
  const { env } = useEnv();

  const [isDisplayingFreeTopic, setDisplayingFreeTopic] = useState(false);
  const [isWaitingTopic, setisWaitingTopic] = useState(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [isDownloading, setDownloading] = useState(false);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [isSearchMode, setSearchMode] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    searchTopics('', 0);
  }, [history.location.pathname, isDisplayingFreeTopic, env]);

  const searchTopics = (keyword: string, offset: number) => {
    if (keyword !== '') {
      setDownloading(true);
      apis.topic
        .search(keyword)
        .then((res) => {
          setTopics(res);
          setDownloading(false);
          setSearchMode(true);
        })
        .catch(() => {
          Alert.error('搜尋課程時發生問題');
          setDownloading(false);
        });
    } else {
      setDownloading(true);
      apis.topic
        .getAll(offset, isDisplayingFreeTopic ? 'free' : 'paid')
        .then((res) => {
          setTopics(res.data);
          setTotal(res.total_count);
          setDownloading(false);
          setSearchMode(false);
        })
        .catch(() => {
          Alert.error('無法查詢所有主題，請嘗試輸入關鍵字');
          setDownloading(false);
          setSearchMode(true);
        });
    }
  };

  const dataInPage = () => {
    try {
      let showTopics: Topic[];
      if (isDisplayingFreeTopic) showTopics = topics.filter((t) => t.is_free);
      else showTopics = topics.filter((t) => !t.is_free);

      if (isWaitingTopic)
        showTopics = showTopics.filter((t) => t.status === 1 || t.status === 8);

      return showTopics.filter((_v, i) => {
        const start = 10 * (page - 1);
        const end = start + 10;
        return i >= start && i < end;
      });
    } catch (err) {
      return [];
    }
  };

  const changePage = (page: number) => {
    if (isSearchMode) {
      setPage(page);
    } else {
      setPage(page);
      setDownloading(true);
      apis.topic
        .getAll(page - 1, isDisplayingFreeTopic ? 'free' : 'paid')
        .then((res) => {
          setTopics(res.data);
          setTotal(res.total_count);
          setDownloading(false);
          setSearchMode(false);
        })
        .catch(() => {
          Alert.error('無法查詢所有頻道，請嘗試輸入關鍵字');
          setDownloading(false);
          setSearchMode(true);
        });
    }
  };

  return (
    <Container className="container">
      <Row>
        <h2>課程管理</h2>
        <h4>Topics Mangement</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <InputGroup inside>
          <Input
            placeholder="使用課程名稱或頻道名稱關鍵字搜尋"
            onPressEnter={() => searchTopics(keyword, 0)}
            value={keyword}
            onChange={(v) => setKeyword(v)}
          />
          <InputGroup.Button onClick={() => searchTopics(keyword, 0)}>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
      </Row>

      <>
        <Row style={{ marginTop: 36 }}>
          <Button
            style={{ marginRight: 16 }}
            size="lg"
            appearance={isDisplayingFreeTopic ? 'default' : 'primary'}
            onClick={() => setDisplayingFreeTopic(false)}
          >
            付費課程
          </Button>

          <Button
            size="lg"
            appearance={isDisplayingFreeTopic ? 'primary' : 'default'}
            onClick={() => {
              setDisplayingFreeTopic(true);
              setisWaitingTopic(false);
            }}
          >
            免費課程
          </Button>
        </Row>
      </>

      <Row style={{ marginTop: 36 }}>
        <Table
          autoHeight
          data={isSearchMode ? dataInPage() : topics}
          onRowClick={(topic) => history.push('/topics/' + topic.id)}
          loading={isDownloading}
        >
          <Column width={360}>
            <HeaderCell>課程名稱</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column width={240}>
            <HeaderCell>課程狀態</HeaderCell>
            <Cell>
              {(rowData: Topic) => {
                return <p>{getTopicStatusDescription(rowData.status)}</p>;
              }}
            </Cell>
          </Column>

          <Column width={100}>
            <HeaderCell>頻道名稱</HeaderCell>
            <Cell dataKey="" />
          </Column>

          <Column width={100}>
            <HeaderCell>定價</HeaderCell>
            <Cell dataKey="" />
          </Column>

          <Column width={100}>
            <HeaderCell>送審時間</HeaderCell>
            <Cell dataKey="" />
          </Column>
        </Table>
        <TablePagination
          showLengthMenu={false}
          displayLength={10}
          activePage={page}
          renderTotal={(v) => <p>總共 {v} 個課程</p>}
          total={isSearchMode ? topics.length : total}
          onChangePage={(v) => changePage(v)}
        />
      </Row>
    </Container>
  );
};

export default Topics;
