import { EventAction, EventState } from './types';

const initState: EventState = { events: [], deletingEventIds: [] };

export function eventReducer(
  state: EventState = initState,
  action: EventAction
): EventState {
  switch (action.type) {
    case 'event/set_events':
      return { ...state, events: action.payload };
    case 'event/set_deleting':
      let newIds = state.deletingEventIds;
      newIds = [...newIds.filter((i) => i !== action.payload.id)];
      if (action.payload.isDeleting) newIds.push(action.payload.id);
      return {
        ...state,
        deletingEventIds: newIds,
      };
    default:
      return state;
  }
}
