import { ThunkAction } from 'redux-thunk';
import { Alert } from 'rsuite';
import { RootAction } from '..';
import {
  deleteEvent as deleteEventService,
  getAllEvent
} from '../../services/event';

export const deleteEvent = (
  eventId: number
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
  return async (dispatch): Promise<void> => {
    try {
      dispatch({
        type: 'event/set_deleting',
        payload: { id: eventId, isDeleting: true },
      });
      await deleteEventService(eventId);
      await dispatch(refreshEvent());
      dispatch({
        type: 'event/set_deleting',
        payload: { id: eventId, isDeleting: false },
      });
    } catch (err) {
      Alert.error(err.toString());
    }
  };
};

export const refreshEvent = (): ThunkAction<
  Promise<void>,
  {},
  {},
  RootAction
> => {
  return async (dispatch): Promise<void> => {
    const events = await getAllEvent();
    dispatch({ type: 'event/set_events', payload: events });
  };
};
