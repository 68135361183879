import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, IconProps, Nav, Sidenav, Toggle } from 'rsuite';
import useEnv from '../../hooks/useEnv';
import useToken from '../../hooks/useToken';

interface ListItem {
  key: string;
  text: string;
  onClick: () => void;
  icon: IconProps['icon'];
  active: boolean;
}

const Sidebar = () => {
  const token = useToken();
  const history = useHistory();
  const {
    env,
    changeEnv
  } = useEnv();

  const [width, setWidth] = useState<number | undefined>(0);

  const updateDimensions = () => {
    setWidth(undefined);
  };

  const listItems: ListItem[] = [
    {
      key: '',
      text: '總覽',
      onClick: () => history.push('/'),
      icon: 'dashboard',
      active: false,
    },
    {
      key: 'topics',
      text: '課程管理',
      onClick: () => history.push('/topics'),
      icon: 'dashboard',
      active: true,
    },
    {
      key: 'orders',
      text: '訂單管理',
      onClick: () => history.push('/orders'),
      icon: 'dashboard',
      active: false,
    },
    {
      key: 'podcasts',
      text: '頻道管理',
      onClick: () => history.push('/podcasts'),
      icon: 'dashboard',
      active: true,
    },
    {
      key: 'podcast-recommend',
      text: '置頂管理',
      onClick: () => history.push('/podcast-recommend'),
      icon: 'dashboard',
      active: true,
    },
    {
      key: 'episodes',
      text: '音頻管理',
      onClick: () => history.push('/episodes'),
      icon: 'dashboard',
      active: false,
    },
    {
      key: 'bankaccounts',
      text: '銀行帳號管理',
      onClick: () => history.push('/bankaccounts'),
      icon: 'dashboard',
      active: false,
    },
    {
      key: 'event',
      text: '活動管理',
      onClick: () => history.push('/event'),
      icon: 'dashboard',
      active: true,
    },
    {
      key: 'section',
      text: '分區管理',
      onClick: () => history.push('/section'),
      icon: 'dashboard',
      active: true,
    },
    {
      key: 'coupon',
      text: '折扣碼管理',
      onClick: () => history.push('/coupon'),
      icon: 'dashboard',
      active: true,
    },
    {
      key: 'case',
      text: '接案管理',
      onClick: () => history.push('/case'),
      icon: 'dashboard',
      active: true,
    },
    {
      key: 'logout',
      text: '登出',
      onClick: () => logout(),
      icon: 'dashboard',
      active: true,
    },
  ];

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    updateDimensions();
  }, []);

  const current = history.location.pathname.split('/')[1];

  const logout = () => {
    token.reset();
  };

  return (
    <div
      ref={(ref) => {
        if (ref && width === undefined) {
          setWidth(ref.clientWidth);
        }
      }}
    >
      <Sidenav
        activeKey={current}
        style={{
          height: '100vh',
          paddingTop: 72,
          position: 'fixed',
          width: width,
        }}
      >
        <Sidenav.Body>
          <Nav>
            {listItems.map((item) => (
              <Nav.Item
                key={item.key}
                eventKey={item.key}
                icon={<Icon icon={item.icon}/>}
                onClick={item.onClick}
                disabled={!item.active}
              >
                <p style={{ whiteSpace: 'nowrap' }}>{item.text}</p>
              </Nav.Item>
            ))}
          </Nav>
          <div
            style={{
              position: 'absolute',
              bottom: 32,
              left: 32,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Toggle
              checked={env === 'production'}
              onChange={(v) => changeEnv(v ? 'production' : 'development')}
            />
            {env === 'production' ? (
              <p
                style={{
                  marginLeft: 18,
                  color: 'yellow',
                }}
              >
                正式站 <br/>
                https://www.hoost.tw
              </p>
            ) : (
              <p
                style={{
                  marginLeft: 18,
                  color: 'gray',
                }}
              >
                測試站 <br/>
                https://dev.hoost.tw/
              </p>
            )}
          </div>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
};

export default Sidebar;
