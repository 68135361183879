import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootAction, RootState } from '..';
import api from '../../services';
import { getOneTopic } from '@services/topic';

export const refreshTopic = (): ThunkAction<Promise<void>,
  RootState,
  {},
  AnyAction> => {
  return async (dispatch: Dispatch<RootAction>, getState): Promise<void> => {
    const env = getState().session.activeEnv;
    const t = getState().session[env]?.token;
    if (!t) return;
    dispatch({
      type: 'topic/set_refreshing_topics',
      isRefreshing: true
    });
    const data = await api.topic.getAll(env, t);
    dispatch({
      type: 'topic/set_topic_list',
      data
    });
    dispatch({
      type: 'topic/set_refreshing_topics',
      isRefreshing: false
    });
  };
};

export const refreshOneTopic = (id: string):
  ThunkAction<Promise<void>, RootState, {}, RootAction> => {
  return async (dispatch, getState) => {
    const env = getState().session.activeEnv;
    const t = getState().session[env]?.token;
    if (!t) return;
    dispatch({
      type: 'topic/set_refreshing_topics',
      isRefreshing: true
    });
    const data = await getOneTopic(id);
    dispatch({
      type: 'topic/set_topic',
      data
    });
    dispatch({
      type: 'topic/set_refreshing_topics',
      isRefreshing: false
    });
  };
};
