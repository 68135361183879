export default function getTopicStatusDescription(
  statusCode: number | undefined
) {
  switch (statusCode) {
    case 0:
      return '可填寫基本資料';
    case 1:
      return '第一階段送審中';
    case 2:
      return '審核通過，等待下一步';
    case 4:
      return '審核拒絕，等待下一步';
    case 8:
      return '第二階段送審中';
    case 16:
      return '審核通過，上架中';
    case 128:
      return '下架中';
    default:
      return '未知的狀態代碼';
  }
}
