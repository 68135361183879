import Coupon, {
  CreateCouponForm,
  EditCouponForm,
  parseCoupon,
} from '@models/Coupon';
import { parseTopic, Topic, UpdateTopicForm } from '@models/Topic';
import { Enviornment } from 'src/types';
import { API_VERSION } from '../config';
import { Case } from '@models/Case';

const authedFetch = (
  url: string,
  method: 'GET' | 'POST',
  body: string | FormData | undefined,
  env: Enviornment,
  token: string | undefined
) => {
  const origin =
    env === 'production'
      ? 'https://www.hoost.tw/api/'
      : 'https://dev.hoost.tw/api/';

  const headers = new Headers({ 'auth-token': token || 'empty' });

  if (typeof body === 'string')
    headers.append('Content-Type', 'application/json');

  return fetch(origin + API_VERSION + url, {
    method,
    body,
    headers,
  });
};

export const api = {
  coupon: {
    getAll: async (env: Enviornment, token: string) => {
      const res = await authedFetch(
        '/admin/coupon/list',
        'GET',
        undefined,
        env,
        token
      );
      const parsedRes = await res.json();
      if (!parsedRes.result) throw new Error();
      return (parsedRes.datas as Coupon[]).map((c) => parseCoupon(c));
    },
    create: async (form: CreateCouponForm, env: Enviornment, token: string) => {
      const res = await authedFetch(
        '/admin/coupon/generate',
        'POST',
        JSON.stringify(form),
        env,
        token
      );
      const parsedRes = await res.json();
      if (res.status !== 200 || !parsedRes.result) throw new Error();
      return parseCoupon(parsedRes);
    },
    update: async (form: EditCouponForm, env: Enviornment, token: string) => {
      const res = await authedFetch(
        '/admin/coupon/update',
        'POST',
        JSON.stringify(form),
        env,
        token
      );
      const parsedRes = await res.json();
      if (res.status !== 200 || !parsedRes.result) throw new Error();
      return parseCoupon(parsedRes);
    },
  },
  topic: {
    getAll: async (env: Enviornment, token: string) => {
      const res = await authedFetch(
        '/admin/topic/list',
        'GET',
        undefined,
        env,
        token
      );
      const parsedRes = await res.json();
      if (!parsedRes.result) throw new Error();
      return (parsedRes.datas as Topic[]).map((c) => parseTopic(c));
    },
    create: async (form: CreateCouponForm, env: Enviornment, token: string) => {
      const res = await authedFetch(
        '/admin/topic/generate',
        'POST',
        JSON.stringify(form),
        env,
        token
      );
      const parsedRes = await res.json();
      if (res.status !== 200 || !parsedRes.result) throw new Error();
      return parseTopic(parsedRes);
    },
    update: async (form: UpdateTopicForm, env: Enviornment, token: string) => {
      const res = await authedFetch(
        '/admin/topic/update',
        'POST',
        JSON.stringify(form),
        env,
        token
      );
      const parsedRes = await res.json();
      if (res.status !== 200 || !parsedRes.result) throw new Error();
      return parseTopic(parsedRes);
    },
  },
  case: {
    getAll: async (env: Enviornment, token: string) => {
      const res = await authedFetch(
        '/case/all',
        'GET',
        undefined,
        env,
        token
      );
      const parsedRes = await res.json();
      if (!parsedRes.result) throw new Error();
      return parsedRes.datas as Case[];
    },
    apply: async (env: Enviornment, token: string, form: any) => {
      const req = new FormData();
      req.append('case_id', form.case_id);
      req.append('user_id', form.user_id);
      req.append('direct_profit', form.direct_profit);
      req.append('share_profit_percent', form.share_profit_percent);
      const res = await authedFetch(
        '/admin/case/apply_and_approve',
        'POST',
        req,
        env,
        token
      );
      const parsedRes = await res.json();
      if (!parsedRes.result) throw new Error();
      return parsedRes.datas;
    }
  },
};

export default api;
