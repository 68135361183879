import { CaseAction, CaseState } from '@store/case/types';

const initState: CaseState = {
  cases: [],
  isRefreshing: false
};

function caseReducer(state = initState, action: CaseAction): CaseState {
  if (typeof action !== 'object' || !action.type.startsWith('case/'))
    return state;

  switch (action.type) {
    case 'case/set_case_list':
      return {
        ...state,
        cases: action.data,
      };
    case 'case/set_refreshing_cases':
      return {
        ...state,
        isRefreshing: action.isRefreshing
      };
    case 'case/set_case':
      return {
        ...state,
        cases: [
          ...state.cases.filter((t) => t.id !== action.data.id),
          action.data,
        ],
      };
    default:
      console.warn('unknown action received', action);
      return state;
  }
}

export default caseReducer;
