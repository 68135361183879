/* eslint-disable indent */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../hooks/useAppSelector';
import { closeModal } from '../store/modal/action';
import { OpenedModal } from '../store/modal/types';

function Modals() {
  const dispath = useDispatch();
  const openedModals = useAppSelector<OpenedModal[]>(
    (s) => s.modals.openedModals
  );

  return (
    <div>
      {openedModals.map((modal) => {
        return (
          <modal.modal
            {...modal.props}
            key={modal.key}
            isOpened={modal.isOpened}
            onClose={() => dispath(closeModal(modal.key))}
          />
        );
      })}
    </div>
  );
}

export default Modals;
