import { Topic } from './Topic';

interface Coupon {
  // id
  id: number;

  // 折扣額度 (percentage: false 代表折扣金額，percentage: true 代表折扣折數 範圍 1~99
  amount: number;

  // 使用上限
  limit: number;

  // 生效時間
  start_at: Date;

  // 過期時間
  expiration: Date;

  // 折扣碼
  code: string;

  // 折扣是否採用折數模式
  percentage: boolean;

  // 目前已使用量
  used: number;

  // 標籤
  label: string;

  // 承擔方
  pay_user: CouponPayUserType;

  created_at: Date;

  updated_at: Date;
}

type CouponPayUserType = 'Together' | 'Hoost' | 'Creator';

export function parseCoupon(data: Coupon) {
  data.created_at = new Date(data.created_at);
  data.expiration = new Date(data.expiration);
  data.start_at = new Date(data.expiration);
  data.updated_at = new Date(data.updated_at);
  return data;
}

export interface CreateCouponForm {
  amount: number;
  limit: number;
  start_at: Date;
  expiration: Date;
  code: string;
  percentage: boolean;
  label: string;
  pay_user: CouponPayUserType;
  topic_ids?: string[];
  topics?: Topic[];
}

export const emptyCreateCouponForm: CreateCouponForm = {
  amount: 0,
  limit: 0,
  start_at: new Date(),
  expiration: new Date(),
  code: '',
  percentage: false,
  label: '',
  pay_user: 'Creator',
  topic_ids: [],
  topics: [],
};

export interface EditCouponForm {
  id: number;
  amount: number;
  limit: number;
  expiration: Date;
  code: string;
  percentage: boolean;
  label: string;
}

export const emptyEditCouponForm: EditCouponForm = {
  id: 0,
  amount: 0,
  limit: 0,
  expiration: new Date(),
  code: '',
  percentage: false,
  label: '',
};

export default Coupon;
