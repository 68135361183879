import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Container, Icon, Input, InputGroup, Row, Table } from 'rsuite';
import TablePagination from 'rsuite/lib/Table/TablePagination';
import useAPIs from '../hooks/useAPIs';
import useEnv from '../hooks/useEnv';
import { Podcast } from '../models';

const { Column, HeaderCell, Cell } = Table;

const Podcasts = () => {
  const apis = useAPIs();
  const history = useHistory();
  const { env } = useEnv();

  const [isDownloading, setDownloading] = useState(false);
  const [page, setPage] = useState(1);
  const [podcasts, setPodcasts] = useState<Podcast[]>([]);

  const [keyword, setKeyword] = useState('');
  const [isSearchMode, setSearchMode] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    searchPodcasts('');
  }, [history.location.pathname, env]);

  const searchPodcasts = (keyword: string) => {
    setPage(1);
    if (keyword !== '') {
      setDownloading(true);
      apis.podcast
        .search(keyword)
        .then((res) => {
          setPodcasts(res);
          setDownloading(false);
          setSearchMode(true);
        })
        .catch(() => {
          Alert.error('搜尋頻道時發生問題');
          setDownloading(false);
          setSearchMode(true);
        });
    } else {
      setDownloading(true);
      apis.podcast
        .getAll(0)
        .then((res) => {
          setPodcasts(res.data);
          setTotal(res.total_count);
          setDownloading(false);
          setSearchMode(false);
        })
        .catch(() => {
          Alert.error('無法查詢所有頻道，請嘗試輸入關鍵字');
          setDownloading(false);
          setSearchMode(true);
        });
    }
  };

  const changePage = (page: number) => {
    if (isSearchMode) {
      setPage(page);
    } else {
      setPage(page);
      setDownloading(true);
      apis.podcast
        .getAll(page - 1)
        .then((res) => {
          setPodcasts(res.data);
          setTotal(res.total_count);
          setDownloading(false);
          setSearchMode(false);
        })
        .catch(() => {
          Alert.error('無法查詢所有頻道，請嘗試輸入關鍵字');
          setDownloading(false);
          setSearchMode(true);
        });
    }
  };

  const dataInPage = () => {
    return podcasts.filter((_v, i) => {
      const start = 10 * (page - 1);
      const end = start + 10;
      return i >= start && i < end;
    });
  };

  return (
    <Container className="container">
      <Row>
        <h2>頻道管理</h2>
        <h4>Podcasts Mangement</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <InputGroup inside>
          <Input
            placeholder="頻道名稱或電子郵件"
            value={keyword}
            onChange={(v) => setKeyword(v)}
            onPressEnter={() => searchPodcasts(keyword)}
          />
          <InputGroup.Button>
            <Icon icon="search" onClick={() => searchPodcasts(keyword)} />
          </InputGroup.Button>
        </InputGroup>
      </Row>

      <Row style={{ marginTop: 36 }}>
        <Table
          autoHeight
          data={isSearchMode ? dataInPage() : podcasts}
          loading={isDownloading}
          onRowClick={(podcast) => {
            history.push('/podcasts/' + podcast.id);
          }}
        >
          <Column width={70}>
            <HeaderCell>Tier</HeaderCell>
            <Cell dataKey="status" />
          </Column>

          <Column width={240}>
            <HeaderCell>頻道名稱</HeaderCell>
            <Cell dataKey="title" />
          </Column>

          <Column width={240}>
            <HeaderCell>電子信箱</HeaderCell>
            <Cell dataKey="email" />
          </Column>
        </Table>
        <TablePagination
          showLengthMenu={false}
          displayLength={10}
          activePage={page}
          renderTotal={(v) => <p>當前查詢總共 {v} 個頻道</p>}
          total={isSearchMode ? podcasts.length : total}
          onChangePage={(v) => changePage(v)}
        />
      </Row>
    </Container>
  );
};

export default Podcasts;
