import axios, { AxiosRequestConfig, Method } from 'axios';
import store from '../store';

export function request(
  method: Method,
  url: string,
  data: string | FormData | undefined,
  params: object
) {
  const env = store.getState().session.activeEnv;
  const token = store.getState().session[env]?.token;

  const baseURL =
    env === 'production'
      ? 'https://www.hoost.tw/api/v1'
      : 'https://dev.hoost.tw/api/v1';

  const config: AxiosRequestConfig = {
    baseURL,
    url,
    method,
    headers: {
      'auth-token': token || 'empty',
      'Content-Type':
        typeof data === 'string' ? 'application/json' : 'multipart/form-data',
    },
    data,
    params,
  };

  return axios(config);
}
