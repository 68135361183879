import useAPIs from '@hooks/useAPIs';
import { getAllEvent } from '@services/event';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Input,
  InputNumber,
  Modal,
  SelectPicker,
  Table
} from 'rsuite';
import { createSection } from '@services/section';
import './index.sass';

interface CreateEventModalProps {
  isOpened: boolean;
  setOpened: (opened: boolean) => void;
  onCreated: () => void;
}

interface CreateSectionFormData {
  title: string;
  description: string;
  data_type: 'topic' | 'episode' | 'podcast' | 'event';
  index: number;
  ids: { id: string; name: string }[];
}

const {
  Column,
  HeaderCell,
  Cell
} = Table;

const initForm: CreateSectionFormData =
  {
    title: '',
    description: '',
    data_type: 'topic',
    index: 1,
    ids: [],
  };

function Index(props: CreateEventModalProps) {
  const {
    isOpened,
    setOpened,
    onCreated
  } = props;

  const API = useAPIs();

  const [keyword, setKeyword] = useState('');
  const [searchResult, setSearchResult] = useState<any[]>();
  const [isSearching, setSearching] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const [formData, setFormData] = useState(initForm);

  async function search() {
    setSearching(true);
    try {
      let res: any[];
      switch (formData.data_type) {
        case 'episode':
          res = await API.episode.search(keyword);
          setSearchResult(res);
          return;
        case 'event':
          res = await getAllEvent();
          setSearchResult(res.filter(r => r.title.includes(keyword)));
          return;
        case 'podcast':
          res = await API.podcast.search(keyword);
          setSearchResult(res);
          return;
        case 'topic':
          res = await API.topic.search(keyword);
          setSearchResult(res);
          return;
      }
    } catch (err) {
      Alert.error('搜尋失敗：' + err.toString());
    } finally {
      setSearching(false);
    }
  }

  useEffect(() => {
    if (formData.data_type === 'event') {
      search();
    }
  }, [formData.data_type]);

  async function create() {
    setCreating(true);
    try {
      await createSection({
        ...formData,
        ids: formData.ids.map((i) => i.id)
      });
      setFormData(initForm);
      setSearchResult([]);
      onCreated();
    } catch (err) {
      Alert.error('新增分區失敗：' + err.toString());
    } finally {
      setCreating(false);
    }
  }

  return (
    <Modal backdrop={true} show={isOpened} onHide={() => setOpened(false)}>
      <Modal.Header>
        <Modal.Title>新增首頁分區</Modal.Title>
      </Modal.Header>
      <Modal.Body className="create-section-modal">
        <div className="form-group">
          <p>標題</p>
          <Input
            value={formData.title}
            onChange={(title) =>
              setFormData({
                ...formData,
                title,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>描述</p>
          <Input
            name="description"
            value={formData.description}
            onChange={(description) =>
              setFormData({
                ...formData,
                description,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>類型</p>
          <SelectPicker
            searchable={false}
            cleanable={false}
            value={formData.data_type}
            onChange={(data_type) => {
              setSearchResult([]);
              setFormData({
                ...formData,
                data_type,
                ids: [],
              });
            }}
            data={[
              {
                label: '頻道',
                value: 'podcast',
              },
              {
                label: '課程',
                value: 'topic',
              },
              {
                label: '單集',
                value: 'episode',
              },
              {
                label: '活動',
                value: 'event',
              },
            ]}
          />
        </div>
        <div className="form-group">
          <p>排序</p>
          <InputNumber
            value={formData.index}
            onChange={(index) =>
              setFormData({
                ...formData,
                index: +index,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>分區內容 (目前 {formData.ids.length} 個)</p>
          {formData.ids.map((item, i) => (
            <div className="selected-item" key={i}>
              <p className="name">{item.name}</p>
              <p className="id">{item.id}</p>
              <Button
                color="red"
                onClick={() => setFormData({
                  ...formData,
                  ids: [...formData.ids.filter(i => i.id !== item.id)]
                })}>移除</Button>
            </div>
          ))}
        </div>
        <div className="form-group">
          <p>加入內容至分區</p>
          <div style={{ display: 'flex' }}>
            <Input
              style={{
                width: 360,
                marginRight: 18,
              }}
              value={keyword}
              onChange={setKeyword}
            />
            <Button appearance="primary" onClick={search}>
              搜尋
            </Button>
          </div>
          <Table data={searchResult} loading={isSearching}>
            <Column width={240}>
              <HeaderCell>名稱</HeaderCell>
              <Cell
                dataKey={formData.data_type === 'topic' ? 'name' : 'title'}
              />
            </Column>
            <Column>
              <HeaderCell>加入分區</HeaderCell>
              <Cell>
                {(rowData: any) => (
                  <Button
                    disabled={
                      formData.ids.find((f) => f.id === rowData.id) !==
                      undefined
                    }
                    onClick={() =>
                      setFormData({
                        ...formData,
                        ids: [
                          ...formData.ids,
                          {
                            id: rowData.id,
                            name:
                              formData.data_type === 'topic'
                                ? rowData.name
                                : rowData.title,
                          },
                        ],
                      })
                    }
                  >
                    加入
                  </Button>
                )}
              </Cell>
            </Column>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={create} appearance="primary" loading={isCreating}>
          確定
        </Button>
        <Button onClick={() => setOpened(false)} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Index;
