import logoIcon from '@assets/logo.svg';
import useEnv from '@hooks/useEnv';
import React from 'react';

const Topbar = () => {
  const { env } = useEnv();
  return (
    <div className="topnav">
      <img alt="Logo" src={logoIcon} />
      <div>
        <h1>Hoost 官方管理後台</h1>
        <p>{env === 'production' ? '正式站' : '測試站'}</p>
      </div>
    </div>
  );
};

export default Topbar;
