import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BankAccounts from '../pages/BankAccounts';
import Episodes from '../pages/Episodes';
import Orders from '../pages/Orders';
import Overview from '../pages/Overview';
import PageNotFound from '../pages/PageNotFound';
import PodcastRecommend from '../pages/PodcastRecommend';
import CouponRouter from './CouponRouter';
import EventRouter from './EventRouter';
import PodcastRouter from './PodcastRouter';
import SectionRouter from './SectionRouter';
import TopicRouter from './TopicRouter';
import Cases from '@pages/Cases';

const MainRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={Overview}/>
      <Route path="/topics" component={TopicRouter}/>
      <Route path="/orders" component={Orders}/>
      <Route path="/podcasts" component={PodcastRouter}/>
      <Route path="/podcast-recommend" component={PodcastRecommend}/>
      <Route path="/episodes" component={Episodes}/>
      <Route path="/bankaccounts" component={BankAccounts}/>
      <Route path="/event" component={EventRouter}/>
      <Route path="/section" component={SectionRouter}/>
      <Route path="/coupon" component={CouponRouter}/>
      <Route path="/case" component={Cases}/>
      <Route component={PageNotFound}/>
    </Switch>
  );
};

export default MainRouter;
