import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TopicDetail from '../pages/TopicDetail';
import Topics from '../pages/Topics';

const TopicRouter = () => {
  return (
    <Switch>
      <Route exact path="/topics" component={Topics}/>
      <Route exact path="/topics/:id" component={TopicDetail}/>
    </Switch>
  );
};

export default TopicRouter;
