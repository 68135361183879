import { default as Event, default as HoostEvent } from '@models/HoostEvent';
import { request } from './http';

export async function deleteEvent(eventId: number): Promise<void> {
  const req = new FormData();
  req.append('event_id', eventId.toString());
  await request('POST', '/admin/section/remove_event', req, {});
}

export async function getAllEvent(): Promise<Event[]> {
  const res = await request('GET', '/admin/section/all_events', undefined, {});
  return res.data.events;
}

export async function getEvent(eventId: number): Promise<Event> {
  const res = await request('GET',
    '/admin/section/get_event',
    undefined,
    { id: eventId, });
  return res.data.event;
}

export async function createEvent(data: Event): Promise<Event> {
  const req = new FormData();
  req.append('id', data.id.toString());
  req.append('title', data.title);
  req.append('description', data.description);
  req.append('url', data.url);
  req.append('tag', data.tag);
  if (data.image) req.append('image', data.image);
  else req.append('image_url', data.image_url);
  const res = await request('POST', '/admin/section/add_event', req, {});
  return res.data.event;
}

export async function updateEvent(data: HoostEvent): Promise<Event> {
  const req = new FormData();
  req.append('id', data.id.toString());
  req.append('title', data.title);
  req.append('description', data.description);
  req.append('url', data.url);
  req.append('tag', data.tag);
  if (data.image) req.append('image', data.image);
  else req.append('image_url', data.image_url);
  const res = await request('POST', '/admin/section/set_event', req, {});
  return res.data.event;
}
