import { useAppDispatch } from '@hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  DatePicker,
  Divider,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  InputNumber,
  Modal,
  Toggle,
} from 'rsuite';
import { emptyEditSkuForm, Sku, Topic } from '@models/Topic';
import {
  setTopicDiscount,
  setTopicSaleTimes,
  updateSku
} from '@services/topic';
import { refreshOneTopic } from '@store/topic/action';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  sku: Sku;
  topic: Topic;
}

function EditSkuModal(props: Props) {
  const [formData, setFormData] = useState(emptyEditSkuForm);
  const [isUpdating, setUpdating] = useState(false);
  const dispatch = useAppDispatch();

  const isFirstSku = props.topic.skus[0]?.id === props.sku.id;

  useEffect(() => {
    setFormData({
      ...formData,
      ...props.sku,
      metadata: props.sku.metadata || emptyEditSkuForm.metadata
    });
  }, []);

  async function submit() {
    setUpdating(true);
    try {
      await updateSku(formData);
      await setTopicDiscount(formData);
      await setTopicSaleTimes(formData);
      props.onClose();
      await dispatch(refreshOneTopic(props.topic.id));
    } catch (err) {
      Alert.error(err.toString());
      console.error(err);
    } finally {
      setUpdating(false);
    }
  }

  return (
    <Modal backdrop={true} show={props.isOpened} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>編輯課程方案 (SKU)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form formValue={formData} onChange={(d: any) => setFormData(d)}>
          <FormGroup>
            <ControlLabel>方案 ID</ControlLabel>
            <FormControl
              value={props.sku.id}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>方案名稱</ControlLabel>
            <FormControl name="name"/>
            <HelpBlock>name</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>方案介紹</ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={10}
              name="description"/>
            <HelpBlock>description</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>售價</ControlLabel>
            <FormControl
              prefix="NT$"
              accepter={InputNumber}
              step={1}
              min={0}
              name="price"/>
            <HelpBlock>price</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>目標人數</ControlLabel>
            <FormControl
              postfix="人"
              accepter={InputNumber}
              step={1}
              min={0}
              name="target_number"/>
            <HelpBlock>target_number</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>購買需填寫的欄位</ControlLabel>
            <FormControl name="require_field"/>
            <HelpBlock>require_field</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>補充名稱</ControlLabel>
            <FormControl name="additional_name"/>
            <HelpBlock>additional_name</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>補充介紹</ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={10}
              name="additional_detail"/>
            <HelpBlock>additional_detail</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>額外售價</ControlLabel>
            <FormControl
              prefix="NT$"
              accepter={InputNumber}
              step={1}
              min={0}
              name="additional_price"/>
            <HelpBlock>additional_price</HelpBlock>
          </FormGroup>
          {isFirstSku && <FormGroup>
            <ControlLabel>預計上線日</ControlLabel>
            <FormControl
              oneTap
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              accepter={DatePicker}
              value={formData.times.content_release_time}
              onChange={(v) => setFormData({
                ...formData,
                times: {
                  ...formData.times,
                  content_release_time: v
                }
              })}
            />
          </FormGroup>}
          <Divider>預購 Pre-Order</Divider>
          <FormGroup>
            <ControlLabel>預購開始時間</ControlLabel>
            <FormControl
              oneTap
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              accepter={DatePicker}
              value={formData.times.pre_order_start}
              onChange={(v) => setFormData({
                ...formData,
                times: {
                  ...formData.times,
                  pre_order_start: v
                }
              })}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>預購截止時間</ControlLabel>
            <FormControl
              oneTap
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              accepter={DatePicker}
              value={formData.times.pre_order_end}
              onChange={(v) => setFormData({
                ...formData,
                times: {
                  ...formData.times,
                  pre_order_end: v
                }
              })}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>預購折扣</ControlLabel>
            <FormControl
              value={formData.discounts.preorder}
              onChange={(v) => setFormData({
                ...formData,
                discounts: {
                  ...formData.discounts,
                  preorder: v
                }
              })}
            />
          </FormGroup>
          <Divider>早鳥 Early-Bird</Divider>
          <FormGroup>
            <ControlLabel>早鳥開始時間</ControlLabel>
            <FormControl
              oneTap
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              accepter={DatePicker}
              value={formData.times.early_bird_start}
              onChange={(v) => setFormData({
                ...formData,
                times: {
                  ...formData.times,
                  early_bird_start: v
                }
              })}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>早鳥截止時間</ControlLabel>
            <FormControl
              oneTap
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              accepter={DatePicker}
              value={formData.times.early_bird_end}
              onChange={(v) => setFormData({
                ...formData,
                times: {
                  ...formData.times,
                  early_bird_end: v
                }
              })}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>早鳥折扣</ControlLabel>
            <FormControl
              value={formData.discounts.earlybird}
              onChange={(v) => setFormData({
                ...formData,
                discounts: {
                  ...formData.discounts,
                  earlybird: v
                }
              })}
            />
          </FormGroup>
          <Divider>一般販售 Normal-Sale</Divider>
          <FormGroup>
            <ControlLabel>一般販售開始時間</ControlLabel>
            <FormControl
              oneTap
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              accepter={DatePicker}
              value={formData.times.normal_sale_start}
              onChange={(v) => setFormData({
                ...formData,
                times: {
                  ...formData.times,
                  normal_sale_start: v
                }
              })}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>一般販售截止時間</ControlLabel>
            <FormControl
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              oneTap
              accepter={DatePicker}
              value={formData.times.normal_sale_end}
              onChange={(v) => setFormData({
                ...formData,
                times: {
                  ...formData.times,
                  normal_sale_end: v
                }
              })}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>一般販售折扣</ControlLabel>
            <FormControl
              value={formData.discounts.normal}
              onChange={(v) => setFormData({
                ...formData,
                discounts: {
                  ...formData.discounts,
                  normal: v
                }
              })}
            />
          </FormGroup>

          {isFirstSku && <React.Fragment>
            <Divider>其他資訊 Metadata</Divider>
            <FormGroup>
              <ControlLabel>預估集數</ControlLabel>
              <FormControl
                value={formData.metadata.except_episode_count}
                onChange={(v) => setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    except_episode_count: v
                  }
                })}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>預估長度</ControlLabel>
              <FormControl
                value={formData.metadata.except_total_audio_length}
                onChange={(v) => setFormData({
                  ...formData,
                  metadata: {
                    ...formData.metadata,
                    except_total_audio_length: v
                  }
                })}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>可離線收聽</ControlLabel>
              <FormControl
                accepter={Toggle}
                checked={formData.metadata.can_offline}
                onChange={v =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData.metadata,
                      can_offline: v
                    }
                  })
                }/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>含簡報圖文</ControlLabel>
              <FormControl
                accepter={Toggle}
                checked={formData.metadata.has_presentation}
                onChange={v =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData.metadata,
                      has_presentation: v
                    }
                  })
                }/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>含重點文稿</ControlLabel>
              <FormControl
                accepter={Toggle}
                checked={formData.metadata.has_key_points_doc}
                onChange={v =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData.metadata,
                      has_key_points_doc: v
                    }
                  })
                }/>
            </FormGroup>
            <FormGroup>
              <ControlLabel>無收聽限制</ControlLabel>
              <FormControl
                accepter={Toggle}
                checked={formData.metadata.no_listen_limit}
                onChange={v =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData.metadata,
                      no_listen_limit: v
                    }
                  })
                }/>
            </FormGroup>
          </React.Fragment>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={submit} appearance="primary" loading={isUpdating}>
          確定
        </Button>
        <Button onClick={() => props.onClose()} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditSkuModal;
