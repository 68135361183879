import CreateCouponModal from '@components/CreateCouponModal';
import EditCouponModal from '@components/EditCouponModal';
import { useAppDispatch, useAppSelector } from '@hooks/useAppSelector';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Icon, IconButton, Row, Table } from 'rsuite';
import Coupon from 'src/models/Coupon';
import { refreshCoupon } from '@store/coupon/action';

const {
  Column,
  HeaderCell,
  Cell
} = Table;

function CouponListPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const coupons = useAppSelector<Coupon[]>((r) => r.coupon.coupons);
  const isRefreshing = useAppSelector<boolean>((r) => r.coupon.isRefreshing);

  useEffect(() => {
    if (history.location.pathname === '/coupon') dispatch(refreshCoupon());
  }, [history.location.pathname]);

  return (
    <Container className="container">
      <Row>
        <h2>折扣碼管理</h2>
        <h4>Coupon Manage</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <IconButton
          appearance="primary"
          icon={<Icon icon="plus"/>}
          onClick={() =>
            dispatch({
              type: 'modals/open_modal',
              modal: CreateCouponModal,
              modalType: 'CREATE_COUPON_MODAL',
              key: '1234',
            })
          }
          placement="left"
        >
          建立折扣碼
        </IconButton>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <Table
          loading={isRefreshing}
          autoHeight
          data={coupons}
          onRowClick={(c) =>
            dispatch({
              type: 'modals/open_modal',
              modal: EditCouponModal,
              props: { couponId: c.id },
            })
          }
        >
          <Column width={120}>
            <HeaderCell>折扣碼</HeaderCell>
            <Cell dataKey="code"/>
          </Column>

          <Column width={120}>
            <HeaderCell>折扣負擔對象</HeaderCell>
            <Cell dataKey="pay_user"/>
          </Column>

          <Column width={120}>
            <HeaderCell>折扣額度</HeaderCell>
            <Cell>
              {(row: Coupon) => {
                if (row.percentage) {
                  return (100 - row.amount) + '%';
                }
                return 'NT$' + row.amount;
              }}
            </Cell>
          </Column>

          <Column width={120}>
            <HeaderCell>有效期限</HeaderCell>
            <Cell>{(row: Coupon) => row.expiration.toLocaleDateString()}</Cell>
          </Column>

          <Column width={80}>
            <HeaderCell>折扣碼名額</HeaderCell>
            <Cell dataKey="limit"/>
          </Column>

          <Column width={360}>
            <HeaderCell>標籤</HeaderCell>
            <Cell dataKey="label"/>
          </Column>
        </Table>
      </Row>
    </Container>
  );
}

export default CouponListPage;
