import { ThunkAction } from 'redux-thunk';
import { RootAction } from '..';
import {
  deleteSection as deleteSectionService,
  getAllSection,
} from '../../services/section';

export const deleteSection = (
  sectionId: number
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
  return async (dispatch): Promise<void> => {
    dispatch({
      type: 'section/set_deleting',
      payload: { id: sectionId, isDeleting: true },
    });
    await deleteSectionService(sectionId);
    await dispatch(refreshSection());
    dispatch({
      type: 'section/set_deleting',
      payload: { id: sectionId, isDeleting: false },
    });
  };
};

export const refreshSection = (): ThunkAction<
  Promise<void>,
  {},
  {},
  RootAction
> => {
  return async (dispatch): Promise<void> => {
    const sections = await getAllSection();
    dispatch({ type: 'section/set_sections', payload: sections });
  };
};
