import { Episode } from './Episode';
import { Podcast } from '@models/Podcast';

export interface Topic {
  id: string;
  name: string;
  description: string;
  image: string;
  fb_image: string;
  banner: string;
  video: string;
  status: number;
  quota: number;
  is_free: boolean;
  contents_html: string;
  topic_entry: {
    episode_id: string;
  } | null;
  episode_count: number;
  total_listen_count: number;
  purchase_count: number;
  subscriber_count: number;
  is_subscribed: boolean;
  is_purchased: boolean;
  podcast: Podcast;
  podcast_id: string;
  created_at: Date;
  updated_at: Date;
  skus: Sku[];
  episodes: Episode[];
}

export interface Sku {
  id: string;
  name: string;
  description: string;
  price: number;
  currency: 'TWD';
  sku_type: 0;
  status: 0;
  require_filed?: string;
  purchase_count?: number;
  limit?: number;
  target_number?: number;
  times: {
    pre_order_start: Date;
    pre_order_end: Date;
    early_bird_start: Date;
    early_bird_end: Date;
    normal_sale_start: Date;
    normal_sale_end: Date;
    content_release_time: Date;
  };
  discounts: {
    normal: number;
    preorder: number;
    earlybird: number;
  };
  metadata: {
    except_episode_count: number;
    except_total_audio_length: number;
    can_offline: boolean;
    no_listen_limit: boolean;
    has_presentation: boolean;
    has_key_points_doc: boolean;
  } | null;
  additional_program?: AdditionalProgram;
}

export interface AdditionalProgram {
  id: string;
  name: string;
  detail: string;
  price: number;
}

export interface UpdateTopicForm {
  name: string;
  description: string;
  image?: File;
  id: string;
  banner?: File;
  video?: File;
  tax_percent: number;
}

export const emptyUpdateTopicForm: UpdateTopicForm = {
  description: '',
  id: '',
  name: '',
  tax_percent: 0,
};

export function parseTopic(data: Topic) {
  data.created_at = new Date(data.created_at);
  data.updated_at = new Date(data.updated_at);
  return data;
}

export interface CreateSkuForm {
  topic_id: string;
  sku_name: string;
  sku_description: string;
  price: number;
  sku_type: 0;
  target_number: number;
  required_field: string;
  additional_name: string;
  additional_detail: string;
  additional_price: number;
}

export const emptyCreateSkuForm: CreateSkuForm = {
  additional_detail: '',
  additional_name: '',
  additional_price: 0,
  price: 0,
  required_field: 'email|phone|address',
  sku_description: '',
  sku_name: '',
  sku_type: 0,
  target_number: 0,
  topic_id: ''
};

export interface EditSkuForm {
  id: string;
  name: string;
  description: string;
  type: 1 | 2;
  price: number;
  target_number: number;
  require_field: string;
  additional_detail: string,
  additional_name: string,
  additional_price: number,
  times: {
    pre_order_start: Date;
    pre_order_end: Date;
    early_bird_start: Date;
    early_bird_end: Date;
    normal_sale_start: Date;
    normal_sale_end: Date;
    content_release_time: Date;
  },
  discounts: {
    normal: number,
    preorder: number,
    earlybird: number
  },
  metadata: {
    except_episode_count: number,
    except_total_audio_length: number,
    can_offline: boolean,
    no_listen_limit: boolean,
    has_presentation: boolean,
    has_key_points_doc: boolean
  }
}

export const emptyEditSkuForm: EditSkuForm = {
  additional_detail: '',
  additional_name: '',
  additional_price: 0,
  description: '',
  id: '',
  name: '',
  price: 0,
  require_field: '',
  target_number: 0,
  type: 1,
  discounts: {
    earlybird: 400,
    normal: 0,
    preorder: 1630
  },
  times: {
    content_release_time: new Date(),
    early_bird_end: new Date(),
    early_bird_start: new Date(),
    normal_sale_end: new Date(),
    normal_sale_start: new Date(),
    pre_order_end: new Date(),
    pre_order_start: new Date()
  },
  metadata: {
    except_episode_count: 26,
    except_total_audio_length: 240,
    can_offline: false,
    no_listen_limit: true,
    has_presentation: true,
    has_key_points_doc: true
  }
};

