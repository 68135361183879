import { SessionAction, SessionState } from './types';

const initState: SessionState = {
  development: undefined,
  production: undefined,
  activeEnv: 'development',
};

export function sessionReducer(
  state = initState,
  action: SessionAction
): SessionState {
  if (typeof action !== 'object' || !action.type.startsWith('session/'))
    return state;

  switch (action.type) {
    case 'session/login':
      return {
        ...state,
        [action.env]: { token: action.token },
      };
    case 'session/logout':
      return { ...state, [action.env]: undefined };
    case 'session/set_active_env':
      return { ...state, activeEnv: action.payload };
    default:
      console.warn('unknown action received', action);
      return state;
  }
}
