import React from 'react';
import { Container, Message, Row } from 'rsuite';

const Overview = () => {
  return (
    <Container className="container">
      <Row>
        <h2>總覽</h2>
        <h4>Overview</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <Message
          type="warning"
          description="本頁面仍在開發中，請選擇其他頁面查看。"
        />
      </Row>
    </Container>
  );
};

export default Overview;
