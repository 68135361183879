import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootAction, RootState } from '..';
import api from '../../services';

export const refreshCoupon = (): ThunkAction<Promise<void>,
  RootState,
  {},
  AnyAction> => {
  return async (dispatch: Dispatch<RootAction>, getState): Promise<void> => {
    const env = getState().session.activeEnv;
    const t = getState().session[env]?.token;
    if (!t) return;
    dispatch({
      type: 'coupon/set_refreshing_coupons',
      isRefreshing: true
    });
    const data = await api.coupon.getAll(env, t);
    dispatch({
      type: 'coupon/set_coupon_list',
      data
    });
    dispatch({
      type: 'coupon/set_refreshing_coupons',
      isRefreshing: false
    });
  };
};
