import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Enviornment } from '../types';
import { useAppDispatch, useAppSelector } from './useAppSelector';

const useEnv = () => {
  const [cookies, setCookie] = useCookies();

  const env = useAppSelector<Enviornment>((r) => r.session.activeEnv);
  const dispatch = useAppDispatch();

  const setEnvInRedux = (env: Enviornment) =>
    dispatch({ type: 'session/set_active_env', payload: env });

  useEffect(() => {
    if (cookies.env === 'production') {
      setEnvInRedux('production');
    } else {
      setEnvInRedux('development');
    }
  }, [cookies.env]);

  const changeEnv = (newEnv: Enviornment) => {
    setEnvInRedux(newEnv);
    setCookie('env', newEnv);
  };

  return { env, changeEnv };
};

export default useEnv;
