import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { Enviornment } from 'src/types';
import { useAppSelector } from './useAppSelector';

const useToken = () => {
  const [, setCookie, deleteCookie] = useCookies();
  const session = useAppSelector((r) => r.session);
  const dispatch = useDispatch();

  const update = (env: Enviornment, newToken: string) => {
    dispatch({ type: 'session/login', token: newToken, env: env });
    if (env === 'production') {
      setCookie('wwwToken', newToken);
    } else {
      setCookie('devToken', newToken);
    }
  };

  const reset = () => {
    dispatch({ type: 'session/logout', env: 'production' });
    dispatch({ type: 'session/logout', env: 'development' });
    deleteCookie('wwwToken');
    deleteCookie('devToken');
  };

  const get = (env: Enviornment) => {
    return session[env]?.token;
  };

  return { get, update, reset };
};

export default useToken;
