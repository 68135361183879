import { CouponAction, CouponState } from './types';

const initState: CouponState = { coupons: [], isRefreshing: false };

function couponReducer(state = initState, action: CouponAction): CouponState {
  if (typeof action !== 'object' || !action.type.startsWith('coupon/'))
    return state;

  switch (action.type) {
    case 'coupon/set_coupon_list':
      return {
        ...state,
        coupons: action.data,
      };
    case 'coupon/set_refreshing_coupons':
      return { ...state, isRefreshing: action.isRefreshing };
    default:
      console.warn('unknown action received', action);
      return state;
  }
}

export default couponReducer;
