import { useAppDispatch, useAppSelector } from '@hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Container, Icon, IconButton, Placeholder, Row } from 'rsuite';
import UpdateSectionModal from '../components/UpdateSectionModal';
import useEnv from '../hooks/useEnv';
import Section from '../models/Section';
import { getSection } from '@services/section';
import { deleteSection } from '@store/section/action';

const SectionDetailPage = () => {
  const { env } = useEnv();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [section, setSection] = useState<Section | undefined>(undefined);
  const isDeleting = useAppSelector<boolean>((r) =>
    r.section.deletingSectionIds.some((i) => i === section?.id)
  );

  const [isEditing, setEditing] = useState(false);

  async function refresh() {
    try {
      const sectionId = +history.location.pathname.split('/')[2];
      const sectionData = await getSection(sectionId);
      setSection(sectionData);
    } catch (err) {
      Alert.error(err.toString());
    }
  }

  useEffect(() => {
    refresh();
  }, [history.location.pathname, env]);

  async function handleDelete() {
    if (!section) return;
    try {
      await dispatch(deleteSection(section.id));
      history.push('/section');
    } catch (err) {
      Alert.error(err.toString());
    }
  }

  const dataList = (): any => {
    if (!section) return [];

    switch (section.data_type) {
      case 'episode':
        if (section.episodes.length === 0) return <h4>無資料</h4>;
        return section.episodes.map((p) => (
          <div
            key={p.id}
            onClick={() => history.push('/episodes/' + p.id)}
            style={{
              cursor: 'pointer',
              height: 128,
              marginTop: 24,
              display: 'flex',
            }}
          >
            <img
              src={p.image ? p.image : ''}
              alt=""
              style={{
                height: 128,
                marginRight: 24,
              }}
            />
            <h4>{p.title}</h4>
          </div>
        ));
      case 'event':
        if (section.events.length === 0) return <h4>無資料</h4>;
        return section.events.map((p) => (
          <div
            key={p.id}
            onClick={() => history.push('/events/' + p.id)}
            style={{
              cursor: 'pointer',
              height: 128,
              marginTop: 24,
              display: 'flex',
            }}
          >
            <img
              src={p.image_url}
              alt=""
              style={{
                height: 128,
                marginRight: 24,
              }}
            />
            <h4>{p.title}</h4>
          </div>
        ));
      case 'podcast':
        if (section.podcasts.length === 0) return <h4>無資料</h4>;
        return section.podcasts.map((p) => (
          <div
            key={p.id}
            onClick={() => history.push('/podcasts/' + p.id)}
            style={{
              cursor: 'pointer',
              height: 128,
              marginTop: 24,
              display: 'flex',
            }}
          >
            <img
              src={p.image ? p.image : ''}
              alt=""
              style={{
                height: 128,
                marginRight: 24,
              }}
            />
            <h4>{p.title}</h4>
          </div>
        ));
      case 'topic':
        if (section.topics.length === 0) return <h4>無資料</h4>;
        return section.topics.map((d) => (
          <div
            key={d.id}
            onClick={() => history.push('/topics/' + d.id)}
            style={{
              cursor: 'pointer',
              height: 128,
              marginTop: 24,
              display: 'flex',
            }}
          >
            <img
              src={d.image}
              alt=""
              style={{
                height: 128,
                marginRight: 24,
              }}
            />
            <h4>{d.name}</h4>
          </div>
        ));
    }
  };

  if (!section) return <Loading/>;

  return (
    <Container className="container">
      <Row>
        <h2>Section Detail</h2>
        <h4>分區管理</h4>
      </Row>

      <Row style={{ marginTop: 36 }}>
        <IconButton
          appearance="primary"
          icon={<Icon icon="pencil"/>}
          onClick={() => setEditing(true)}
          placement="left"
        >
          編輯分區
        </IconButton>
        <IconButton
          style={{ marginLeft: 16 }}
          color="red"
          icon={<Icon icon="trash"/>}
          onClick={handleDelete}
          loading={isDeleting}
          placement="left"
        >
          刪除分區
        </IconButton>
      </Row>

      <div>
        <Row style={{ marginTop: 48 }}>
          <p>分區標題</p>
          <h4>{section.title}</h4>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>分區介紹</p>
          <h4>{section.description}</h4>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>分區類型</p>
          <h4>{section.data_type}</h4>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>分區排序</p>
          <h4>{section.index}</h4>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>內容列表</p>
          {dataList()}
        </Row>
      </div>

      <UpdateSectionModal
        defaultData={section}
        isOpened={isEditing}
        setOpened={setEditing}
        onUpdated={async () => {
          await refresh();
          setEditing(false);
        }}
      />
    </Container>
  );
};

function Loading() {
  return (
    <Container className="container">
      <Row>
        <h2>Section Detail</h2>
        <h4>分區管理</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <IconButton loading appearance="primary" icon={<Icon icon="pencil"/>}>
          編輯分區
        </IconButton>
      </Row>
      <div>
        <Row style={{ marginTop: 48 }}>
          <p>分區標題</p>
          <Placeholder.Paragraph width={240} active/>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>分區介紹</p>
          <Placeholder.Paragraph width={240} active/>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>分區類型</p>
          <Placeholder.Paragraph width={240} active/>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>分區排序</p>
          <Placeholder.Paragraph width={240} active/>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <p>內容列表</p>
          {[0, 1, 2].map((i) => (
            <div
              key={i}
              style={{
                height: 128,
                marginTop: 24,
                display: 'flex',
              }}
            >
              <Placeholder.Graph width={128} active/>
            </div>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default SectionDetailPage;
