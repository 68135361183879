import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PodcastDetail from '../pages/PodcastDetail';
import Podcasts from '../pages/Podcasts';

const PodcastRouter = () => {
  return (
    <Switch>
      <Route exact path="/podcasts" component={Podcasts}/>
      <Route exact path="/podcasts/:id" component={PodcastDetail}/>
    </Switch>
  );
};

export default PodcastRouter;
