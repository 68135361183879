/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Container,
  Divider,
  Icon,
  IconButton,
  Loader,
  Row
} from 'rsuite';
import useAPIs from '../hooks/useAPIs';
import useEnv from '../hooks/useEnv';
import { Episode, Podcast, Topic } from '../models';
import '../styles/TopicDetail.sass';
import { useAppDispatch } from '@hooks/useAppSelector';
import EditPodcastModal from '@components/EditPodcastModal';

const PodcastDetail = () => {
  const apis = useAPIs();
  const { env } = useEnv();
  const history = useHistory();
  const dispath = useAppDispatch();

  const [podcast, setPodcast] = useState<Podcast | undefined>(undefined);
  const [topics, setTopics] = useState<Topic[] | undefined>(undefined);
  const [episodes, setEpisodes] = useState<Episode[] | undefined>(undefined);

  const [isSettingRole, setSettingRole] = useState(false);

  const refresh = () => {
    const podcastID = history.location.pathname.split('/')[2];
    apis.podcast
      .get(podcastID)
      .then((podcast) => {
        setPodcast(podcast);

        apis.podcast
          .getTopics(podcast.id)
          .then((res) => setTopics(res))
          .catch((err) => Alert.error(err));

        apis.podcast
          .getEpisodes(podcastID)
          .then((res) => setEpisodes(res))
          .catch((err) => Alert.error(err));
      })
      .catch((err) => Alert.error(err.toString()));
  };

  const getSubscriptions = () => {
    let n = 0;
    if (topics) {
      topics.map((t) => {
        n += t.subscriber_count;
        return null;
      });
    }
    return n;
  };

  useEffect(() => {
    refresh();
  }, [history.location.pathname, env]);

  const togglePodcastRole = () => {
    if (podcast) {
      setSettingRole(true);
      apis.podcast
        .setRole(podcast.id, !podcast.is_creator)
        .then(() => setSettingRole(false))
        .then(() => refresh());
      return;
    }
    Alert.error('發生錯誤');
  };

  return (
    <Container className="container">
      <Link to="/podcasts">{'<'} 返回頻道列表</Link>
      <div
        className={podcast ? 'loaded' : 'loading'}
        style={{ paddingTop: 48 }}
      >
        <Col xs={16}>
          <Row>
            <Row>
              <Col xs={8}>
                <img
                  style={{
                    width: '100%',
                    minHeight: 120,
                  }}
                  src={podcast?.image ? podcast.image : ''}
                  alt="無法載入頻道封面"
                />
              </Col>
              <Col xs={16} style={{ padding: '0 36px' }}>
                <p className="field_name">課程介紹</p>
                <h5>
                  {podcast?.description
                    ? podcast.description.substr(0, 250)
                    : '暫無介紹'}
                </h5>
              </Col>
            </Row>
            <Row style={{ marginTop: 36 }}>
              <h2>{podcast?.title}</h2>
              <p
                style={{
                  opacity: 0.4,
                  fontSize: 10,
                  marginTop: 16,
                }}
              >
                頻道 ID <br/>
                {podcast?.id}
              </p>
            </Row>
          </Row>

          <div style={{ marginBottom: 72 }}>
            <Divider>管理操作</Divider>
            <IconButton
              appearance="primary"
              icon={<Icon icon="pencil"/>}
              onClick={() => dispath({
                type: 'modals/open_modal',
                modal: EditPodcastModal,
                props: { podcastId: podcast?.id }
              })}
              placement="left"
            >
              編輯頻道資訊
            </IconButton>
            <div className="status_buttons">
              {podcast?.is_creator ? (
                <>
                  <Button color="green" style={{ cursor: 'default' }}>
                    當前可以上架付費內容
                  </Button>
                  <Button
                    appearance="subtle"
                    onClick={togglePodcastRole}
                    loading={isSettingRole}
                  >
                    點擊禁止
                  </Button>
                </>
              ) : (
                <>
                  <Button color="red" style={{ cursor: 'default' }}>
                    當前禁止上架付費內容
                  </Button>
                  <Button
                    appearance="subtle"
                    onClick={togglePodcastRole}
                    loading={isSettingRole}
                  >
                    點擊允許
                  </Button>
                </>
              )}
            </div>
          </div>

          <div>
            <Divider>頻道經營狀況</Divider>
            <Row>
              <Col xs={8} style={{ marginBottom: 36 }}>
                <p className="field_name">免費主題數量</p>
                <h5>{topics?.filter((t) => t.is_free).length}</h5>
              </Col>

              <Col xs={8} style={{ marginBottom: 36 }}>
                <p className="field_name">付費主題數量</p>
                <h5>{topics?.filter((t) => !t.is_free).length}</h5>
              </Col>

              <Col xs={8} style={{ marginBottom: 36 }}>
                <p className="field_name">單集內容數量</p>
                {episodes ? <h5>{episodes.length} 集</h5> : <Loader/>}
              </Col>

              <Col xs={8} style={{ marginBottom: 36 }}>
                <p className="field_name">追蹤人數</p>
                <h5>{getSubscriptions()} 人</h5>
              </Col>
            </Row>
          </div>

          <div style={{ marginBottom: 72 }}>
            <Divider>頻道檔案</Divider>
            <Row>
              {podcast?.email ? (
                <Col xs={12}>
                  <p className="field_name">電子郵件</p>
                  <a href={'mailto:' + podcast?.email}>
                    <h5>{podcast?.email}</h5>
                  </a>
                </Col>
              ) : (
                <></>
              )}

              {podcast?.website ? (
                <Col xs={12}>
                  <p className="field_name">頻道網站</p>
                  <a href={podcast?.website}>
                    <h5>{podcast?.website}</h5>
                  </a>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>

          <div style={{ marginTop: 72 }}>
            <Divider>單集內容上架</Divider>
            <Row>
              {podcast?.itunes_id ? (
                <Col xs={8}>
                  <Row>
                    <p className="field_name">iTuens ID</p>
                    <h5>{podcast?.itunes_id}</h5>
                  </Row>
                </Col>
              ) : (
                <></>
              )}

              {podcast?.rss ? (
                <Col xs={24}>
                  <p className="field_name">RSS Feed</p>
                  <a href={podcast?.rss}>
                    <h5>{podcast?.rss}</h5>
                  </a>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Col>
        <Col xs={8} style={{ padding: '0 24px' }}>
          <p className="field_name">課程列表</p>
          <div className={topics ? 'loaded' : 'loading'}>
            {topics ? (
              topics.length === 0 ? (
                <h5>該頻道還沒有課程</h5>
              ) : (
                topics.map((topic) => (
                  <div key={topic.id} className="list_item_outer">
                    <div
                      className="list_item_inner"
                      onClick={() =>
                        history.push(
                          '/topics/' +
                          topic.id +
                          '?back_to=' +
                          history.location.pathname
                        )
                      }
                    >
                      <h5>{topic.name}</h5>
                      <p style={{ color: topic.is_free ? 'green' : 'orange' }}>
                        {topic.is_free ? '免費主題' : '付費主題'}
                      </p>
                      <p>建立時間 {topic.created_at}</p>
                      <p>總收聽 {topic.total_listen_count} 次</p>
                    </div>
                  </div>
                ))
              )
            ) : (
              <Loader center content="loading"/>
            )}
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default PodcastDetail;
