import { request } from './http';
import { CaseUser } from '@models/Case';

interface SearchCaseUserParams {
  email?: string;
  id_number?: string;
  id?: string;
  display_name?: string;
}

export async function searchUser(params: SearchCaseUserParams) {
  const res = await request('GET', '/admin/user/search', undefined, params);
  return res.data.datas as CaseUser[];
}
