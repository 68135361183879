import { ModalAction, ModalsState } from './types';

const initState: ModalsState = { openedModals: [] };

function modalsReducer(state = initState, action: ModalAction): ModalsState {
  if (typeof action !== 'object' || !action.type.startsWith('modals/'))
    return state;

  switch (action.type) {
    case 'modals/open_modal':
      return {
        ...state,
        openedModals: [
          ...state.openedModals,
          {
            modal: action.modal,
            key: new Date().getTime().toString(),
            isOpened: true,
            props: action.props,
          },
        ],
      };
    case 'modals/close_modal':
      const closingModal = state.openedModals.find((m) => m.key === action.key);
      if (!closingModal) return state;
      closingModal.isOpened = false;
      return {
        ...state,
        openedModals: [
          ...state.openedModals.filter((m) => m.key !== action.key),
          closingModal,
        ],
      };
    case 'modals/remove_modal':
      let newModals = [...state.openedModals];
      newModals = newModals.filter((m) => m.key !== action.key);
      return {
        ...state,
        openedModals: newModals,
      };
    default:
      console.warn('unknown action received', action);
      return state;
  }
}

export default modalsReducer;
