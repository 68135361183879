import { getAllEvent } from '@services/event';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Input,
  InputNumber,
  Modal,
  SelectPicker,
  Table
} from 'rsuite';
import useAPIs from '../../hooks/useAPIs';
import { Episode, Podcast, Topic } from '../../models';
import HoostEvent from '../../models/HoostEvent';
import { updateSection } from '@services/section';

interface UpdateSectionModalProps {
  defaultData: any;
  isOpened: boolean;
  setOpened: (opened: boolean) => void;
  onUpdated: () => void;
}

interface UpdateSectionFormData {
  title: string;
  description: string;
  data_type: 'topic' | 'episode' | 'podcast' | 'event';
  index: number;
  ids: { id: string; name: string }[];
}

const {
  Column,
  HeaderCell,
  Cell
} = Table;

function UpdateSectionModal(props: UpdateSectionModalProps) {
  const {
    defaultData,
    isOpened,
    setOpened,
    onUpdated
  } = props;

  const API = useAPIs();

  const [keyword, setKeyword] = useState('');
  const [searchResult, setSearchResult] = useState<any[]>();
  const [isSearching, setSearching] = useState(false);
  const [isCreating, setCreating] = useState(false);

  const [formData, setFormData] = useState<UpdateSectionFormData>({
    title: '',
    description: '',
    data_type: 'topic',
    index: 1,
    ids: [],
  });

  const getDefaultIdArray = (): { id: string; name: string }[] => {
    switch (defaultData.data_type) {
      case 'episode':
        return defaultData.episodes.map((p: Episode) => {
          return {
            id: p.id,
            name: p.title,
          };
        });
      case 'event':
        return defaultData.events.map((p: HoostEvent) => {
          return {
            id: p.id,
            name: p.title,
          };
        });
      case 'podcast':
        return defaultData.podcasts.map((p: Podcast) => {
          return {
            id: p.id,
            name: p.title,
          };
        });
      case 'topic':
        return defaultData.topics.map((p: Topic) => {
          return {
            id: p.id,
            name: p.name,
          };
        });
      default:
        return new Array<{ id: string; name: string }>();
    }
  };

  async function search() {
    setSearching(true);
    try {
      let res: any[];
      switch (formData.data_type) {
        case 'episode':
          res = await API.episode.search(keyword);
          setSearchResult(res);
          return;
        case 'event':
          res = await getAllEvent();
          setSearchResult(res);
          return;
        case 'podcast':
          res = await API.podcast.search(keyword);
          setSearchResult(res);
          return;
        case 'topic':
          res = await API.topic.search(keyword);
          setSearchResult(res);
          return;
      }
    } catch (err) {
      Alert.error('搜尋失敗：' + err.toString());
    } finally {
      setSearching(false);
    }
  }

  useEffect(() => {
    setFormData({
      ...defaultData,
      ids: getDefaultIdArray(),
    });
  }, [defaultData]);

  async function update() {
    setCreating(true);
    try {
      await updateSection({
        ...formData,
        id: props.defaultData.id,
        ids: formData.ids.map((i) => i.id),
      });
      onUpdated();
    } catch (err) {
      Alert.error('編輯分區失敗：' + err.toString());
    } finally {
      setCreating(false);
    }
  }

  return (
    <Modal backdrop={true} show={isOpened} onHide={() => setOpened(false)}>
      <Modal.Header>
        <Modal.Title>編輯首頁分區</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <p>標題</p>
          <Input
            value={formData.title}
            onChange={(title) =>
              setFormData({
                ...formData,
                title,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>描述</p>
          <Input
            name="description"
            value={formData.description}
            onChange={(description) =>
              setFormData({
                ...formData,
                description,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>類型</p>
          <SelectPicker
            searchable={false}
            cleanable={false}
            value={formData.data_type}
            onChange={(data_type) => {
              setSearchResult([]);
              setFormData({
                ...formData,
                data_type,
                ids: [],
              });
            }}
            data={[
              {
                label: '頻道',
                value: 'podcast',
              },
              {
                label: '課程',
                value: 'topic',
              },
              {
                label: '單集',
                value: 'episode',
              },
              {
                label: '活動',
                value: 'event',
              },
            ]}
          />
        </div>
        <div className="form-group">
          <p>排序</p>
          <InputNumber
            value={formData.index}
            onChange={(index) =>
              setFormData({
                ...formData,
                index: +index,
              })
            }
          />
        </div>
        <div className="form-group">
          <p>分區內容 (目前 {formData.ids.length} 個)</p>
          <Table data={formData.ids}>
            <Column width={240}>
              <HeaderCell>ID</HeaderCell>
              <Cell dataKey="id"/>
            </Column>
            <Column width={240}>
              <HeaderCell>名稱</HeaderCell>
              <Cell dataKey="name"/>
            </Column>
          </Table>
        </div>
        <div className="form-group">
          <p>加入內容至分區</p>
          <div style={{ display: 'flex' }}>
            <Input
              style={{
                width: 360,
                marginRight: 18,
              }}
              value={keyword}
              onChange={setKeyword}
            />
            <Button appearance="primary" onClick={search}>
              搜尋
            </Button>
          </div>
          <Table data={searchResult} loading={isSearching}>
            <Column width={240}>
              <HeaderCell>名稱</HeaderCell>
              <Cell
                dataKey={
                  formData.data_type === 'topic' ||
                  formData.data_type === 'podcast'
                    ? 'name'
                    : 'title'
                }
              />
            </Column>
            <Column>
              <HeaderCell>加入分區</HeaderCell>
              <Cell>
                {(rowData: any) => (
                  <Button
                    disabled={
                      formData.ids.find((f: any) => f.id === rowData.id) !==
                      undefined
                    }
                    onClick={() =>
                      setFormData({
                        ...formData,
                        ids: [
                          ...formData.ids,
                          {
                            id: rowData.id,
                            name: rowData.name,
                          },
                        ],
                      })
                    }
                  >
                    加入
                  </Button>
                )}
              </Cell>
            </Column>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={update} appearance="primary" loading={isCreating}>
          確定
        </Button>
        <Button onClick={() => setOpened(false)} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateSectionModal;
