import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CouponListPage from '../pages/CouponListPage';

const CouponRouter = () => {
  return (
    <Switch>
      <Route exact path="/coupon" component={CouponListPage} />
    </Switch>
  );
};

export default CouponRouter;
