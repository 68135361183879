import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootAction, RootState } from '..';
import { getAllPodcast, getPodcast } from '@services/podcast';

export const refreshPodcast = (): ThunkAction<Promise<void>,
  RootState,
  {},
  AnyAction> => {
  return async (dispatch: Dispatch<RootAction>): Promise<void> => {
    dispatch({
      type: 'podcast/set_refreshing_podcasts',
      isRefreshing: true
    });
    const data = await getAllPodcast();
    dispatch({
      type: 'podcast/set_podcast_list',
      data
    });
    dispatch({
      type: 'podcast/set_refreshing_podcasts',
      isRefreshing: false
    });
  };
};

export const refreshOnePodcast = (podcastId: string): ThunkAction<Promise<void>,
  RootState,
  {},
  AnyAction> => {
  return async (dispatch: Dispatch<RootAction>): Promise<void> => {
    const data = await getPodcast(podcastId);
    await dispatch({
      type: 'podcast/set_podcast',
      data
    });
  };
};
