import { PodcastAction, PodcastState } from './types';

const initState: PodcastState = {
  podcasts: [],
  isRefreshing: false
};

function podcastReducer(state = initState,
  action: PodcastAction): PodcastState {
  if (typeof action !== 'object' || !action.type.startsWith('podcast/'))
    return state;

  switch (action.type) {
    case 'podcast/set_podcast_list':
      return {
        ...state,
        podcasts: action.data,
      };
    case 'podcast/set_refreshing_podcasts':
      return {
        ...state,
        isRefreshing: action.isRefreshing
      };
    case 'podcast/set_podcast':
      return {
        ...state,
        podcasts: [
          ...state.podcasts.filter(p => p.id !== action.data.id),
          action.data
        ]
      };
    default:
      return state;
  }
}

export default podcastReducer;
