import CreateSectionModal from '@components/CreateSectionModal';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Container, Icon, IconButton, Row, Table } from 'rsuite';
import Section from '../models/Section';
import { getAllSection } from '../services/section';

const { Column, HeaderCell, Cell } = Table;

const SectionListPage = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [sectionData, setSectionData] = useState<Section[]>();
  const [isCreatingSection, setCreatingSection] = useState(false);

  async function refresh() {
    setLoading(true);
    try {
      const res = await getAllSection();
      setSectionData(res);
    } catch (err) {
      Alert.error('從後端請求 Section 資料失敗：' + err.toString());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (history.location.pathname === '/section') refresh();
  }, [history.location.pathname]);

  return (
    <Container className="container">
      <Row>
        <h2>首頁分區管理</h2>
        <h4>Sections Manage</h4>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <IconButton
          appearance="primary"
          icon={<Icon icon="plus" />}
          onClick={() => setCreatingSection(true)}
          placement="left"
        >
          新增分區
        </IconButton>
      </Row>
      <Row style={{ marginTop: 36 }}>
        <Table
          autoHeight
          data={sectionData}
          onRowClick={(topic) => history.push('/section/' + topic.id)}
          loading={isLoading}
        >
          <Column width={240}>
            <HeaderCell>標題</HeaderCell>
            <Cell dataKey="title" />
          </Column>

          <Column width={360}>
            <HeaderCell>描述</HeaderCell>
            <Cell dataKey="description" />
          </Column>

          <Column width={100}>
            <HeaderCell>類型</HeaderCell>
            <Cell dataKey="data_type" />
          </Column>

          <Column width={100}>
            <HeaderCell>排序</HeaderCell>
            <Cell dataKey="index" />
          </Column>
        </Table>
      </Row>

      <CreateSectionModal
        isOpened={isCreatingSection}
        setOpened={setCreatingSection}
        onCreated={async () => {
          setCreatingSection(false);
          await refresh();
        }}
      />
    </Container>
  );
};

export default SectionListPage;
