import React, { useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  Table,
  Toggle,
} from 'rsuite';
import useAPIs from '../../hooks/useAPIs';
import { emptyCreateCouponForm } from '@models/Coupon';
import { createCoupon } from '@services/coupon';

const {
  Column,
  HeaderCell,
  Cell
} = Table;

function CreateCouponModal(props: ModalProps) {
  const API = useAPIs();
  const [formData, setFormData] = useState(emptyCreateCouponForm);
  const [keyword, setKeyword] = useState('');
  const [searchResult, setSearchResult] = useState<any[]>();
  const [isSearching, setSearching] = useState(false);
  const [isCreating, setCreating] = useState(false);

  async function search() {
    setSearching(true);
    try {
      let res = await API.topic.search(keyword);
      setSearchResult(res.filter(r => !r.is_free));
    } catch (err) {
      Alert.error('搜尋失敗：' + err.toString());
    } finally {
      setSearching(false);
    }
  }

  async function submit() {
    setCreating(true);
    try {
      if (formData.percentage) formData.amount = 100 - formData.amount;
      await createCoupon(formData);
      props.onClose();
    } catch (err) {
      Alert.error(err.toString());
    } finally {
      setCreating(false);
    }
  }

  return (
    <Modal backdrop={true} show={props.isOpened} onHide={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>新增折扣碼</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form formValue={formData} onChange={(d: any) => setFormData(d)}>
          <FormGroup>
            <ControlLabel>折扣碼</ControlLabel>
            <FormControl name="code"/>
          </FormGroup>
          <FormGroup>
            <ControlLabel>人數限制</ControlLabel>
            <FormControl name="limit" accepter={InputNumber}/>
          </FormGroup>
          <FormGroup>
            <ControlLabel>開始日期</ControlLabel>
            <FormControl
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              accepter={DatePicker}
              name="start_at"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>到期日</ControlLabel>
            <FormControl
              format="YYYY-MM-DD HH:mm:ss"
              cleanable={false}
              accepter={DatePicker}
              name="expiration"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>以百分比折扣</ControlLabel>
            <FormControl accepter={Toggle} name="percentage"/>
          </FormGroup>
          <FormGroup>
            <ControlLabel>折扣額度</ControlLabel>
            <FormControl
              name="amount"
              accepter={InputNumber}
              prefix={formData.percentage ? undefined : 'NT$'}
              postfix={formData.percentage ? '%' : undefined}
            />
            {formData.percentage && <HelpBlock>輸入 80 代表八折</HelpBlock>}
          </FormGroup>
          <FormGroup>
            <ControlLabel>標籤</ControlLabel>
            <FormControl name="label"/>
          </FormGroup>
          <FormGroup>
            <ControlLabel>承擔對象</ControlLabel>
            <FormControl
              searchable={false}
              cleanable={false}
              accepter={SelectPicker}
              name="pay_user"
              data={[
                {
                  label: 'Hoost',
                  value: 'Hoost'
                },
                {
                  label: '創作者',
                  value: 'Creator'
                },
                {
                  label: '共同承擔',
                  value: 'Together'
                },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>適用於全部課程</ControlLabel>
            <FormControl
              accepter={Toggle}
              onChange={v => v
                ? setFormData({
                  ...formData,
                  topics: undefined,
                  topic_ids: undefined
                })
                : setFormData({
                  ...formData,
                  topics: [],
                  topic_ids: []
                })}
            />
          </FormGroup>
        </Form>

        {formData.topic_ids && formData.topics && <React.Fragment>
          <p style={{ margin: '12px 0' }}>
            適用該折扣碼的課程 (目前 {formData.topic_ids.length} 個)
          </p>
          {formData.topics.map((item, i) => (
            <div className="selected-item" key={i}>
              <p className="name">{item.name}</p>
              <p className="id">{item.id}</p>
            </div>
          ))}
          <p style={{ margin: '8px 0' }}>選擇適用該折扣碼的課程</p>
          <div
            style={{
              display: 'flex',
              margin: '8px 0'
            }}>
            <Input
              style={{
                width: 360,
                marginRight: 18,
              }}
              value={keyword}
              onChange={setKeyword}
            />
            <Button appearance="primary" onClick={search}>
              搜尋
            </Button>
          </div>
          <Table data={searchResult} loading={isSearching}>
            <Column width={240}>
              <HeaderCell>名稱</HeaderCell>
              <Cell dataKey="name"/>
            </Column>
            <Column>
              <HeaderCell/>
              <Cell>
                {(rowData: any) => (
                  <Button
                    disabled={
                      formData.topic_ids?.find((f) => f === rowData.id) !==
                      undefined
                    }
                    onClick={() =>
                      setFormData({
                        ...formData,
                        topics: [...formData.topics || [], rowData],
                        topic_ids: [...formData.topic_ids || [], rowData.id],
                      })
                    }
                  >
                    加入
                  </Button>
                )}
              </Cell>
            </Column>
          </Table>
        </React.Fragment>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={submit} appearance="primary" loading={isCreating}>
          確定
        </Button>
        <Button onClick={() => props.onClose()} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateCouponModal;
