import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

export const closeModal = (
  key: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch): Promise<void> => {
    dispatch({ type: 'modals/close_modal', key });
    setTimeout(() => {
      dispatch({ type: 'modals/remove_modal', key });
    }, 500);
  };
};
